import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { verifyEmail } from "../../services/api";
import useUserData from "../../hooks/user/useUserData";
import ForgetPasswordForm from "./ForgetPasswordForm";

const VerifyEmail = ({ onVerificationSuccess }) => {
  const [verificationStatus, setVerificationStatus] = useState("loading");
  const [error, setError] = useState("");
  const [responseData, setResponseData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { forceUpdateUserData } = useUserData();
  const verificationAttempted = useRef(false);
  const [showForgetPasswordForm, setShowForgetPasswordForm] = useState(false);
  const [forgetPasswordData, setForgetPasswordData] = useState(null);

  const performVerification = useCallback(async () => {
    if (verificationAttempted.current) return;
    verificationAttempted.current = true;

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const userId = searchParams.get("userId");
    const isResetPassword = searchParams.get("isResetPassword") === "true";

    if (!token || !userId) {
      setVerificationStatus("invalid");
      return;
    }

    try {
      let response;
      if (isResetPassword) {
        response = await verifyEmail(token, userId, true);
      } else {
        response = await verifyEmail(token, userId);
      }

      setResponseData(response);

      if (response.success) {
        setVerificationStatus("success");
        // Cập nhật userData mới
        if (!isResetPassword) {
          await forceUpdateUserData(response.user.id);
        }
      } else {
        setVerificationStatus("error");
        setError(response.message || "Có lỗi xảy ra trong quá trình xác minh.");
      }
    } catch (error) {
      setVerificationStatus("error");
      setError(
        error.response?.data?.message ||
          "Có lỗi xảy ra trong quá trình xác minh."
      );
    }
  }, [location, forceUpdateUserData]);

  useEffect(() => {
    performVerification();
  }, [performVerification]);

  useEffect(() => {
    if (verificationStatus === "success") {
      onVerificationSuccess(responseData);
    }
  }, [verificationStatus, responseData, onVerificationSuccess]);

  const handleGoHome = () => {
    navigate("/");
  };

  const renderContent = () => {
    const searchParams = new URLSearchParams(location.search);
    const isResetPassword = searchParams.get("isResetPassword") === "true";

    switch (verificationStatus) {
      case "loading":
        return (
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500 mb-4"></div>
            <p className="text-lg font-semibold">Đang xác minh...</p>
          </div>
        );
      case "success":
        return (
          <div className="flex flex-col items-center">
            <CheckCircleIcon
              style={{ fontSize: 64, color: "#10B981" }}
              className="mb-4"
            />
            <h2 className="text-2xl font-bold text-green-600 mb-2">
              THÀNH CÔNG
            </h2>
            <p className="text-gray-600 mb-4">
              {isResetPassword
                ? "Token đặt lại mật khẩu hợp lệ. Bạn sẽ được chuyển hướng đến trang đặt lại mật khẩu."
                : "Email của bạn đã được xác minh. Bạn sẽ được chuyển hướng về trang chủ sau 30 giây."}
            </p>
            {!isResetPassword && (
              <button
                onClick={handleGoHome}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              >
                Về trang chủ ngay
              </button>
            )}
          </div>
        );
      case "error":
        return (
          <div className="flex flex-col items-center">
            <ErrorIcon
              style={{ fontSize: 64, color: "#EF4444" }}
              className="mb-4"
            />
            <h2 className="text-2xl font-bold text-red-600 mb-2">THẤT BẠI</h2>
            <p className="text-gray-600 mb-4">{error}</p>
            <button
              onClick={handleGoHome}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Về trang chủ
            </button>
          </div>
        );
      case "invalid":
        return (
          <div className="flex flex-col items-center">
            <ErrorIcon
              style={{ fontSize: 64, color: "#F59E0B" }}
              className="mb-4"
            />
            <h2 className="text-2xl font-bold text-yellow-600 mb-2">
              KHÔNG HỢP LỆ
            </h2>
            <p className="text-gray-600 mb-4">
              Liên kết xác minh không hợp lệ hoặc đã hết hạn. Vui lòng kiểm tra
              lại email của bạn.
            </p>
            <button
              onClick={handleGoHome}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Về trang chủ
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen w-full bg-gradient-to-r from-purple-600 to-blue-500 font-sans">
      <div className="bg-white p-8 rounded-lg shadow-md text-center max-w-md">
        {showForgetPasswordForm ? (
          <ForgetPasswordForm {...forgetPasswordData} />
        ) : (
          renderContent()
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
