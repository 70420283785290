import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const CopyrightModal = ({ open, onClose }) => {
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [open, onClose]);

  if (!open) return null;

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
      onClick={handleOutsideClick}
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-auto overflow-hidden">
        <div className="bg-blue-600 text-white py-3 px-3">
          <h2 className="text-xl font-bold text-center">THÔNG TIN BẢN QUYỀN</h2>
        </div>
        <div className="p-6 space-y-4 max-h-[70vh] overflow-y-auto">
          <div>
            <h5 className="font-semibold mb-2 uppercase">BẢN ĐỒ VÀ DỮ LIỆU</h5>
            <p className="text-sm">
              Dữ liệu bản đồ được cung cấp bởi{" "}
              <a
                href="https://www.openstreetmap.org/copyright"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                OpenStreetMap
              </a>
              , © Những người đóng góp cho OpenStreetMap. Hiển thị bản đồ sử
              dụng{" "}
              <a
                href="https://leafletjs.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Leaflet
              </a>
              , một thư viện JavaScript mã nguồn mở.
            </p>
          </div>
          <div>
            <h5 className="font-semibold mb-2 uppercase">
              NỘI DUNG VÀ THÔNG TIN
            </h5>
            <p className="text-sm">
              Tất cả nội dung và thông tin về khác thuộc bản quyền của
              Giaothong.Info © 2024.
            </p>
          </div>
          <div>
            <h5 className="font-semibold mb-2 uppercase">SỬ DỤNG</h5>
            <p className="text-sm">
              Việc sử dụng ứng dụng này đồng nghĩa với việc bạn đồng ý tuân thủ
              các điều khoản bản quyền của các bên liên quan.
            </p>
          </div>
        </div>
        <div className="bg-gray-100 px-3 py-3 flex justify-end">
          <button
            onClick={onClose}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
          >
            Đóng
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default CopyrightModal;
