import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { trackUser } from "../../services/api";
import { getFromIndexedDB } from "../../utils/indexedDB";

export const useUserTracking = () => {
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const initializeTracking = async () => {
      let currentSessionId = sessionStorage.getItem("sessionId");
      const userId = await getFromIndexedDB("userId");

      if (!currentSessionId) {
        currentSessionId = uuidv4();
        sessionStorage.setItem("sessionId", currentSessionId);
      }

      setSessionId(currentSessionId);

      const userAgent = navigator.userAgent;
      const screenResolution = `${window.screen.width}x${window.screen.height}`;
      const language = navigator.language;
      const referrer = document.referrer;

      const browserInfo = getBrowserInfo();

      try {
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        const ipAddress = ipData.ip;

        let location = "Unknown";
        try {
          const mapViewString = localStorage.getItem("mapView");
          if (mapViewString) {
            const mapView = JSON.parse(mapViewString);
            const [lat, lon] = mapView.center;

            const nominatimResponse = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=18&addressdetails=1`
            );
            const locationData = await nominatimResponse.json();

            if (locationData.address) {
              const city =
                locationData.address.city ||
                locationData.address.town ||
                locationData.address.village ||
                "Unknown";
              const state = locationData.address.state || "Unknown";
              const country = locationData.address.country || "Unknown";
              location = `${city}, ${state}, ${country}`;
            }
          }
        } catch (locationError) {
          console.error("Lỗi khi lấy thông tin vị trí:", locationError);
        }

        await trackUser({
          sessionId: currentSessionId,
          userAgent,
          screenResolution,
          language,
          referrer,
          ipAddress,
          userId,
          location,
          browser: browserInfo.browser,
          browserVersion: browserInfo.version,
        });
      } catch (error) {
        console.error("Lỗi khi xử lý dữ liệu theo dõi:", error);
      }
    };

    initializeTracking();
  }, []);

  return { sessionId };
};

// Hàm để lấy thông tin trình duyệt
function getBrowserInfo() {
  const ua = navigator.userAgent;
  let browser = "Unknown";
  let version = "Unknown";

  // Detect browser
  if (ua.indexOf("Firefox") > -1) {
    browser = "Firefox";
    version = ua.match(/Firefox\/(\d+)/)[1];
  } else if (ua.indexOf("Chrome") > -1) {
    browser = "Chrome";
    version = ua.match(/Chrome\/(\d+)/)[1];
  } else if (ua.indexOf("Safari") > -1) {
    browser = "Safari";
    version = ua.match(/Version\/(\d+)/)[1];
  } else if (ua.indexOf("MSIE") > -1 || ua.indexOf("Trident/") > -1) {
    browser = "Internet Explorer";
    version = ua.match(/(?:MSIE |rv:)(\d+)/)[1];
  } else if (ua.indexOf("Edge") > -1) {
    browser = "Edge";
    version = ua.match(/Edge\/(\d+)/)[1];
  }

  return { browser, version };
}
