import React from "react";
import { useLocation } from "react-router-dom";
import HomeAvatar from "./HomeAvatar";
import SearchBar from "./SearchBar";
import { useAuth } from "../../hooks/user/useAuth";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ActionButtons from "./ActionButtons";

const HomeMenu = ({
  onSearchFocus,
  userPosition,
  onSearchStateChange,
  selectedLocation,
  onSearchSelect,
  onSearchClear,
  searchTerm,
  onSearchTermChange,
  onEditButtonClick,
  isEditMode,
  mapRef,
  onLocationSelect,
  onFilterChange,
}) => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const showEditButton =
    location.pathname === "/" || location.pathname === "/editor";

  const handleFilterChange = (filters) => {
    onFilterChange(filters);
  };

  const buttonStyle = {
    height: "37px", // Đặt chiều cao cố định cho nút
  };

  return (
    <div className="absolute top-0 left-0 right-0 z-20 p-3 flex justify-between items-start">
      {/* SearchBar và ActionButtons */}
      <div className="flex items-center flex-grow">
        <div className={`${isMobile ? "w-3/4" : "w-64 lg:w-80"} mr-2`}>
          <SearchBar
            onFocus={onSearchFocus}
            userPosition={userPosition}
            onSearchStateChange={onSearchStateChange}
            selectedLocation={selectedLocation}
            onSelect={onSearchSelect}
            onClear={onSearchClear}
            searchTerm={searchTerm}
            onSearchTermChange={onSearchTermChange}
            mapRef={mapRef}
            onLocationSelect={onLocationSelect}
            onFilterChange={handleFilterChange}
          />
        </div>
        {showEditButton && (
          <div className={`${isMobile ? "w-3/10" : ""}`}>
            <ActionButtons
              isEditMode={isEditMode}
              onEditButtonClick={onEditButtonClick}
              isMobile={isMobile}
            />
          </div>
        )}
      </div>

      {/* HomeAvatar hoặc nút Đăng nhập */}
      <div className={`flex items-center ${isMobile ? "w-1/10 ml-1" : "ml-2"}`}>
        {isLoggedIn ? (
          !isMobile && <HomeAvatar />
        ) : (
          <Button
            variant="contained"
            color="primary"
            href="/login"
            startIcon={<PersonIcon />}
            size={isMobile ? "small" : "medium"}
            style={buttonStyle}
            className="flex items-center justify-center"
          >
            {isMobile ? "Đăng nhập" : "Đăng nhập"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default HomeMenu;
