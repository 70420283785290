import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../../../hooks/user/useAuth";
import {
  updateUserProfile,
  getRoleByUserId,
  getLevelByUserId,
  sendVerificationEmail,
  sendResetPasswordEmail,
} from "../../../services/api";
import toast from "react-hot-toast";
import { format, parse, isValid } from "date-fns";
import { getFromIndexedDB, saveToIndexedDB } from "../../../utils/indexedDB";
import CIcon from "@coreui/icons-react";
import {
  cilUser,
  cilEnvelopeClosed,
  cilPhone,
  cilCalendar,
  cilLockLocked,
} from "@coreui/icons";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const UserProfile = () => {
  const { user, login } = useAuth();

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    gender: "",
    avatar: null,
  });

  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userLevel, setUserLevel] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [previewAvatar, setPreviewAvatar] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Thêm state mới
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetPasswordCountdown, setResetPasswordCountdown] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userId = await getFromIndexedDB("userId");
          const userData = await getFromIndexedDB("userData");
          setFormData({
            full_name: userData.full_name || "",
            email: userData.email || "",
            phone_number: userData.phone_number || "",
            gender: userData.gender || "",
            avatar: userData.avatar || "",
          });
          setPreviewAvatar(
            userData.avatar
              ? `/media/avatars/${userData.avatar}`
              : "/media/avatars/default-avatar.png"
          );
          if (userData.date_of_birth) {
            const parsedDate = parseDate(userData.date_of_birth);
            if (parsedDate) {
              setDateOfBirth(parsedDate);
            } else {
              console.warn("Invalid date format received from server");
            }
          }
          fetchUserRole(userId);
          fetchUserLevel(userId);
        } catch (error) {
          console.error("Lỗi khi lấy thông tin người dùng:", error);
          toast.error(
            "Không thể tải thông tin người dùng. Vui lòng thử lại sau."
          );
        }
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  // Đếm ngược thời gian cho nút đổi mật khẩu
  useEffect(() => {
    if (resetPasswordCountdown > 0) {
      const timer = setTimeout(
        () => setResetPasswordCountdown(resetPasswordCountdown - 1),
        1000
      );
      return () => clearTimeout(timer);
    }
  }, [resetPasswordCountdown]);

  const parseDate = (dateString) => {
    const formats = ["yyyy-MM-dd", "dd/MM/yyyy", "MM/dd/yyyy"];
    for (const formatString of formats) {
      const parsedDate = parse(dateString, formatString, new Date());
      if (isValid(parsedDate)) {
        return parsedDate;
      }
    }
    return null;
  };

  const fetchUserRole = async (userId) => {
    try {
      const response = await getRoleByUserId(userId);
      setUserRole(response.data);
    } catch (error) {
      console.error("Lỗi khi lấy thông tin vai trò người dùng:", error);
      toast.error("Không thể tải thông tin vai trò. Vui lòng thử lại sau.");
    }
  };

  const fetchUserLevel = async (userId) => {
    try {
      const response = await getLevelByUserId(userId);
      setUserLevel(response.data);
    } catch (error) {
      console.error("Lỗi khi lấy thông tin cấp độ người dùng:", error);
      toast.error("Không thể tải thông tin cấp độ. Vui lòng thử lại sau.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox" && name === "gender") {
      setFormData({ ...formData, gender: checked ? value : "" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleDateChange = (date) => {
    setDateOfBirth(date);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploading(true);
      try {
        // Xử lý tải ảnh lên
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewAvatar(reader.result);
        };
        reader.readAsDataURL(file);
        setSelectedFile(file);
        // Giả lập thời gian tải lên
        await new Promise((resolve) => setTimeout(resolve, 1000));
        toast.success("Tải ảnh mới thành công");
      } catch (error) {
        console.error("Lỗi khi tải ảnh:", error);
        toast.error("Có lỗi xảy ra khi tải ảnh mới.");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleFocus = (name) => {
    setFocusedInput(name);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("full_name", formData.full_name);
      formDataToSend.append("phone_number", formData.phone_number);
      formDataToSend.append("gender", formData.gender);

      if (dateOfBirth) {
        formDataToSend.append(
          "date_of_birth",
          format(dateOfBirth, "yyyy-MM-dd")
        );
      }

      if (selectedFile) {
        formDataToSend.append("avatar", selectedFile);
      }

      const userId = await getFromIndexedDB("userId");
      const updatedUser = await updateUserProfile(userId, formDataToSend);

      setFormData((prev) => ({
        ...prev,
        ...updatedUser,
      }));
      if (updatedUser.date_of_birth) {
        const parsedDate = parseDate(updatedUser.date_of_birth);
        if (parsedDate) {
          setDateOfBirth(parsedDate);
        }
      }

      const token = await getFromIndexedDB("token");
      await login(token, userId);

      await saveToIndexedDB("user", updatedUser);

      toast.success("Cập nhật thông tin thành công");
    } catch (error) {
      console.error("Lỗi khi cập nhật thông tin:", error);
      toast.error("Có lỗi xảy ra khi cập nhật thông tin.");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSendVerificationEmail = async () => {
    setIsVerifying(true);
    try {
      await sendVerificationEmail(formData.email);
      toast.success(
        "Email xác minh đã được gửi. Vui lòng kiểm tra hộp thư của bạn."
      );
      setCountdown(30);
    } catch (error) {
      console.error("Lỗi khi gửi email xác minh:", error);
      toast.error("Không thể gửi email xác minh. Vui lòng thử lại sau.");
    } finally {
      setIsVerifying(false);
    }
  };

  // Xử lý mở modal đổi mật khẩu
  const handleOpenResetPasswordModal = () => {
    setIsResetPasswordModalOpen(true);
  };

  // Xử lý đóng modal đổi mật khẩu
  const handleCloseResetPasswordModal = () => {
    setIsResetPasswordModalOpen(false);
  };

  // Xử lý gửi email đổi mật khẩu
  const handleResetPassword = async () => {
    setIsResettingPassword(true);
    try {
      await sendResetPasswordEmail(formData.email);
      toast.success(
        "Vui lòng kiểm tra hộp thư và làm theo hướng dẫn để đổi mật khẩu."
      );
      setResetPasswordCountdown(60);
      handleCloseResetPasswordModal();
    } catch (error) {
      console.error("Lỗi khi gửi email đổi mật khẩu:", error);
      toast.error("Không thể gửi email đổi mật khẩu. Vui lòng thử lại sau.");
    } finally {
      setIsResettingPassword(false);
    }
  };

  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap -mx-4">
        {/* Phần ảnh đại diện */}
        <div className="w-full lg:w-1/3 px-4">
          <div className="bg-white rounded-lg shadow-md h-full">
            <div className="p-3 border-b border-gray-200 font-semibold">
              Ảnh đại diện
            </div>
            <div className="p-4 text-center">
              <img
                src={previewAvatar}
                alt="Ảnh đại diện"
                className="mx-auto rounded-full w-32 h-32 mb-4 object-cover"
              />
              <div className="text-sm text-gray-600 mb-4">
                JPG hoặc PNG không quá 5MB
              </div>
              <LoadingButton
                variant="contained"
                component="label"
                loading={isUploading}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded cursor-pointer transition duration-300"
              >
                {isUploading ? "Đang tải..." : "Tải ảnh mới"}
                <input
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </LoadingButton>
            </div>
          </div>
        </div>
        {/* Phần thông tin chi tiết */}
        <div className="w-full lg:w-2/3 px-4">
          <div className="bg-white rounded-lg shadow-md h-full">
            <div className="p-3 border-b border-gray-200 font-semibold flex justify-between items-center">
              <span>Thông tin tài khoản</span>
              <div className="flex items-center space-x-2">
                {userRole && (
                  <img
                    src={`/media/roles/${userRole.role_icon}`}
                    alt="Icon vai trò"
                    className="w-6 h-6"
                    title={userRole.role_name}
                  />
                )}
                {userLevel && (
                  <img
                    src={`/media/levels/${userLevel.level_icon}`}
                    alt="Icon cấp độ"
                    className="w-6 h-6"
                    title={userLevel.level_name}
                  />
                )}
              </div>
            </div>
            <div className="p-4">
              <form onSubmit={handleSubmit}>
                <div className="mb-4 flex items-center">
                  <div className="relative flex-grow mr-4">
                    <CIcon
                      icon={cilUser}
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    />
                    <input
                      type="text"
                      id="full_name"
                      name="full_name"
                      value={formData.full_name}
                      onChange={handleInputChange}
                      onFocus={() => handleFocus("full_name")}
                      onBlur={handleBlur}
                      className={`w-full pl-10 pr-3 py-2 rounded-md border ${
                        focusedInput === "full_name"
                          ? "border-blue-500 ring-2 ring-blue-200"
                          : "border-gray-300"
                      } focus:outline-none focus:border-blue-500 transition duration-300`}
                      placeholder="Họ và tên"
                    />
                  </div>
                  <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        name="gender"
                        value="male"
                        checked={formData.gender === "male"}
                        onChange={handleInputChange}
                        className="form-checkbox text-blue-600"
                      />
                      <span className="ml-2">Nam</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        name="gender"
                        value="female"
                        checked={formData.gender === "female"}
                        onChange={handleInputChange}
                        className="form-checkbox text-blue-600"
                      />
                      <span className="ml-2">Nữ</span>
                    </label>
                  </div>
                </div>
                <div className="mb-4 flex items-center space-x-4">
                  <div className="relative flex-grow">
                    <CIcon
                      icon={cilEnvelopeClosed}
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    />
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      readOnly
                      disabled
                      className="w-full pl-10 pr-3 py-2 rounded-md border border-gray-300 bg-gray-100"
                      placeholder="Email"
                    />
                    {user.status === "verified" && (
                      <CheckCircleIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-green-500" />
                    )}
                  </div>
                  {user.status !== "verified" && (
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleSendVerificationEmail}
                      loading={isVerifying}
                      disabled={countdown > 0}
                      className="py-2 px-4 text-sm"
                    >
                      {countdown > 0 ? `Gửi lại (${countdown}s)` : "Xác minh"}
                    </LoadingButton>
                  )}
                </div>
                <div className="mb-4 flex space-x-4">
                  <div className="relative flex-1">
                    <CIcon
                      icon={cilPhone}
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    />
                    <input
                      type="tel"
                      id="phone_number"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      onFocus={() => handleFocus("phone_number")}
                      onBlur={handleBlur}
                      className={`w-full pl-10 pr-3 py-2 rounded-md border ${
                        focusedInput === "phone_number"
                          ? "border-blue-500 ring-2 ring-blue-200"
                          : "border-gray-300"
                      } focus:outline-none focus:border-blue-500 transition duration-300`}
                      placeholder="Số điện thoại"
                    />
                  </div>
                  <div className="relative flex-1">
                    <CIcon
                      icon={cilCalendar}
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 z-10"
                    />
                    <DatePicker
                      selected={dateOfBirth}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                      className={`w-full pl-10 pr-3 py-2 rounded-md border ${
                        focusedInput === "date_of_birth"
                          ? "border-blue-500 ring-2 ring-blue-200"
                          : "border-gray-300"
                      } focus:outline-none focus:border-blue-500 transition duration-300`}
                      placeholderText="Ngày sinh"
                      onFocus={() => handleFocus("date_of_birth")}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                {/* Nút cập nhật thông tin và đổi mật khẩu */}
                <div className="flex justify-between space-x-4">
                  <LoadingButton
                    variant="contained"
                    color="warning"
                    onClick={handleOpenResetPasswordModal}
                    disabled={resetPasswordCountdown > 0}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 flex items-center"
                    startIcon={<CIcon icon={cilLockLocked} />}
                  >
                    {resetPasswordCountdown > 0
                      ? `Đổi mật khẩu (${resetPasswordCountdown}s)`
                      : "Đổi mật khẩu"}
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isUpdating}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                  >
                    {isUpdating ? "Đang cập nhật..." : "Cập nhật thông tin"}
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Modal xác nhận đổi mật khẩu */}
      {isResetPasswordModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Xác nhận đổi mật khẩu</h2>
            <p className="mb-4">Bạn có chắc chắn muốn đổi mật khẩu không?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCloseResetPasswordModal}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Hủy
              </button>
              <LoadingButton
                onClick={handleResetPassword}
                loading={isResettingPassword}
                variant="contained"
                color="primary"
              >
                {isResettingPassword ? "Đang gửi..." : "Xác nhận"}
              </LoadingButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
