import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { getFromIndexedDB } from "../../../utils/indexedDB";
import { createUserFeedback, getMapSignById } from "../../../services/api";
import toast from "react-hot-toast";

// Schema validation cho form
const schema = yup.object().shape({
  name: yup.string().required("Họ và tên là bắt buộc"),
  email: yup.string().email("Email không hợp lệ").required("Email là bắt buộc"),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, "Số điện thoại không hợp lệ")
    .required("Số điện thoại là bắt buộc"),
  type: yup.string().required("Vui lòng chọn loại góp ý"),
  mapSignId: yup
    .string()
    .test(
      "conditional-required",
      "Vui lòng nhập ID biển báo",
      function (value) {
        return (
          this.parent.type !== "map_sign_feedback" ||
          (this.parent.type === "map_sign_feedback" && !!value)
        );
      }
    ),
  content: yup.string().required("Nội dung góp ý là bắt buộc"),
});

const FeedbackModal = ({
  open,
  onClose,
  mapSignId = null,
  initialFeedbackType = "",
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      type: initialFeedbackType || "",
      mapSignId: mapSignId || "",
      content: "",
    },
  });

  const selectedType = watch("type");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getFromIndexedDB("userData");
        if (userData) {
          setIsLoggedIn(true);
          setValue("name", userData.full_name);
          setValue("email", userData.email);
          setValue("phone", userData.phone_number || "");
          setUserId(userData.id);
        } else {
          setIsLoggedIn(false);
          setValue("name", "");
          setValue("email", "");
          setValue("phone", "");
          setUserId(null);
        }
      } catch (error) {
        console.error("Lỗi khi lấy thông tin người dùng:", error);
        toast.error(
          "Không thể tải thông tin người dùng. Vui lòng thử lại sau."
        );
      }
    };

    if (open) {
      fetchUserData();
      // Đặt giá trị mặc định cho type và mapSignId
      setValue("type", initialFeedbackType || "");
      setValue("mapSignId", mapSignId || "");
    }
  }, [open, setValue, initialFeedbackType, mapSignId]);

  const validateMapSignId = async (id) => {
    try {
      const response = await getMapSignById(id);
      return response && response.id ? true : false;
    } catch (error) {
      return false;
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      if (data.type === "map_sign_feedback") {
        const isValidMapSign = await validateMapSignId(data.mapSignId);
        if (!isValidMapSign) {
          setError("mapSignId", {
            type: "manual",
            message: "ID biển báo không tồn tại",
          });
          setIsSubmitting(false);
          return;
        }
      }

      const feedbackData = {
        user_id: userId,
        user_email: data.email,
        user_phone_number: data.phone,
        type: data.type,
        map_sign_id: data.type === "map_sign_feedback" ? data.mapSignId : null,
        detail: data.content,
      };

      const response = await createUserFeedback(feedbackData);

      if (response && response.id) {
        toast.success("Góp ý đã được gửi thành công!");
        handleClose();
      } else {
        toast.error("Có lỗi xảy ra khi gửi góp ý. Vui lòng thử lại.");
      }
    } catch (error) {
      console.error("Lỗi khi gửi góp ý:", error);
      let errorMessage = "Có lỗi xảy ra khi gửi góp ý. Vui lòng thử lại sau.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    reset({
      name: "",
      email: "",
      phone: "",
      type: "",
      mapSignId: "",
      content: "",
    });
    onClose();
  };

  return ReactDOM.createPortal(
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 ${
        open ? "" : "hidden"
      }`}
      onClick={(e) => e.target === e.currentTarget && handleClose()}
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl mx-auto overflow-hidden">
        {/* Phần tiêu đề */}
        <div className="bg-blue-600 text-white py-3 px-4">
          <h2 className="text-xl font-bold text-center">ĐÓNG GÓP Ý KIẾN</h2>
        </div>

        {/* Phần nội dung */}
        <div className="p-6 space-y-4 max-h-[70vh] overflow-y-auto">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      placeholder="Họ và tên"
                      className={`w-full px-3 py-2 border rounded-md ${
                        isLoggedIn ? "bg-gray-100 cursor-not-allowed" : ""
                      }`}
                      disabled={isLoggedIn}
                      title={
                        isLoggedIn ? "Không thể chỉnh sửa khi đã đăng nhập" : ""
                      }
                    />
                  )}
                />
                {errors.name && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.name.message}
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="tel"
                      placeholder="Số điện thoại"
                      className={`w-full px-3 py-2 border rounded-md ${
                        isLoggedIn ? "bg-gray-100 cursor-not-allowed" : ""
                      }`}
                      disabled={isLoggedIn}
                      title={
                        isLoggedIn ? "Không thể chỉnh sửa khi đã đăng nhập" : ""
                      }
                    />
                  )}
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.phone.message}
                  </p>
                )}
              </div>
            </div>
            <div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="email"
                    placeholder="Email"
                    className={`w-full px-3 py-2 border rounded-md ${
                      isLoggedIn ? "bg-gray-100 cursor-not-allowed" : ""
                    }`}
                    disabled={isLoggedIn}
                    title={
                      isLoggedIn ? "Không thể chỉnh sửa khi đã đăng nhập" : ""
                    }
                  />
                )}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <select
                      {...field}
                      className={`w-full px-3 py-2 border rounded-md ${
                        initialFeedbackType === "map_sign_feedback"
                          ? "bg-gray-100 cursor-not-allowed"
                          : ""
                      }`}
                      disabled={initialFeedbackType === "map_sign_feedback"}
                      title={
                        initialFeedbackType === "map_sign_feedback"
                          ? "Không thể thay đổi loại góp ý cho biển báo cụ thể"
                          : ""
                      }
                    >
                      <option value="">Chọn vấn đề góp ý</option>
                      <option value="map_sign_feedback">
                        Góp ý về biển báo
                      </option>
                      <option value="general_feedback">Góp ý chung</option>
                      <option value="technical_feedback">Góp ý kỹ thuật</option>
                    </select>
                  )}
                />
                {errors.type && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.type.message}
                  </p>
                )}
              </div>
              {selectedType === "map_sign_feedback" && (
                <div>
                  <Controller
                    name="mapSignId"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        placeholder="ID biển báo"
                        className={`w-full px-3 py-2 border rounded-md ${
                          initialFeedbackType === "map_sign_feedback"
                            ? "bg-gray-100 cursor-not-allowed"
                            : ""
                        }`}
                        disabled={initialFeedbackType === "map_sign_feedback"}
                        title={
                          initialFeedbackType === "map_sign_feedback"
                            ? "Không thể chỉnh sửa ID biển báo"
                            : ""
                        }
                      />
                    )}
                  />
                  {errors.mapSignId && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.mapSignId.message}
                    </p>
                  )}
                </div>
              )}
            </div>
            <div>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    placeholder="Nội dung góp ý"
                    className="w-full px-3 py-2 border rounded-md"
                    rows={4}
                  />
                )}
              />
              {errors.content && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.content.message}
                </p>
              )}
            </div>
            <div className="flex justify-end space-x-2">
              <Button
                variant="outlined"
                onClick={handleClose}
                disabled={isSubmitting}
              >
                Hủy
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                loadingPosition="start"
              >
                {isSubmitting ? "Đang gửi..." : "Gửi góp ý"}
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default FeedbackModal;
