import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Tooltip,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  ListItemIcon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useSignsData from "../../hooks/sign/useSignsData";
import toast from "react-hot-toast";
import FeedbackModal from "./modal/FeedbackModal";
import CopyrightModal from "./modal/CopyrightModal";
import { useAuth } from "../../hooks/user/useAuth";
import HomeIcon from "@mui/icons-material/Home";
import CopyrightIcon from "@mui/icons-material/Copyright";
import FeedbackIcon from "@mui/icons-material/Feedback";

const ActionButtons = ({ isEditMode, onEditButtonClick, isMobile }) => {
  const { refreshData } = useSignsData();
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const [refreshCountdown, setRefreshCountdown] = useState(0);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const [isCopyrightModalVisible, setIsCopyrightModalVisible] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn, user } = useAuth();

  const handleRefreshClick = () => {
    if (isRefreshDisabled) return;

    toast.promise(
      refreshData().then((refreshedData) => {
        return refreshedData;
      }),
      {
        loading: "Đang làm mới toàn bộ dữ liệu...",
        success: "Tất cả dữ liệu đã được làm mới thành công",
        error: (err) => `Lỗi khi làm mới dữ liệu: ${err.message}`,
      }
    );
    setIsRefreshDisabled(true);
    setRefreshCountdown(30);
  };

  const handleFeedbackClick = () => {
    setIsFeedbackModalVisible(true);
  };

  const handleCopyrightClick = () => {
    setIsCopyrightModalVisible(true);
  };

  const handleHomeClick = () => {
    navigate("/home");
  };

  useEffect(() => {
    let timer;
    if (isRefreshDisabled && refreshCountdown > 0) {
      timer = setInterval(() => {
        setRefreshCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else if (refreshCountdown === 0) {
      setIsRefreshDisabled(false);
    }
    return () => clearInterval(timer);
  }, [isRefreshDisabled, refreshCountdown]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const loginUrl = `/login?redirect=${encodeURIComponent("/home")}`;

  const menuItems = [
    { text: "TRANG CHỦ", onClick: handleHomeClick, icon: <HomeIcon /> },
    {
      text: "BẢN QUYỀN",
      onClick: handleCopyrightClick,
      icon: <CopyrightIcon />,
    },
    { text: "LIÊN HỆ", onClick: handleFeedbackClick, icon: <FeedbackIcon /> },
  ];

  const drawerContent = (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-2 bg-gray-800">
        <IconButton onClick={toggleDrawer(false)} className="text-white">
          <CloseIcon />
        </IconButton>
        <img
          src="/media/logos/1728034380491.png"
          alt="Logo"
          className="h-12 w-auto mx-auto"
          onClick={() => navigate("/home")}
        />
        <div className="w-10"></div>
      </div>
      <Divider />
      <List className="flex-grow px-4">
        {isLoggedIn ? (
          <ListItem
            button
            onClick={() => {
              navigate("/user");
              setDrawerOpen(false);
            }}
          >
            <Avatar
              src={`media/avatars/${user.avatar}`}
              alt={user.full_name}
              sx={{ width: 56, height: 56, marginRight: 2 }}
            />
            <div className="flex-grow">
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {user.full_name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Thiết lập tài khoản
              </Typography>
            </div>
            <ArrowForwardIosIcon />
          </ListItem>
        ) : (
          <ListItem
            button
            onClick={() => {
              navigate(loginUrl);
              setDrawerOpen(false);
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText
              primary="Đăng nhập / Tạo tài khoản"
              primaryTypographyProps={{
                variant: "h6",
                style: { fontWeight: "bold" },
              }}
            />
            <ArrowForwardIosIcon />
          </ListItem>
        )}
        <Divider />
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              onClick={() => {
                item.onClick();
                setDrawerOpen(false);
              }}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  variant: "h6",
                  style: { fontWeight: "bold" },
                }}
              />
            </ListItem>
            {index < menuItems.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
      <div className="mt-auto p-4 bg-gray-800 text-white">
        <div className="grid grid-cols-1 gap-4">
          <div>
            <h3 className="text-lg font-semibold mb-2">Liên kết nhanh</h3>
            <ul className="space-y-1">
              <li>
                <a href="/" className="text-sm">
                  Bản đồ biển báo giao thông
                </a>
              </li>
              <li>
                <a href="/home/data" className="text-sm">
                  Trang dữ liệu thống kê
                </a>
              </li>
              <li>
                <a href="/home/contact" className="text-sm">
                  Trang liên hệ
                </a>
              </li>
              <li>
                <a href="/home/copyrights" className="text-sm">
                  Thông tin bản quyền
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Thông tin liên hệ</h3>
            <p className="text-sm">Email: info@giaothong.info</p>
            <div className="flex space-x-4 mt-2">
              <a
                href="https://zalo.me/0938871357"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/media/icons/zalo.png"
                  alt="Zalo"
                  className="w-6 h-6"
                />
              </a>
              <a
                href="https://t.me/minhhoang_1105"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/media/icons/telegram.png"
                  alt="Telegram"
                  className="w-6 h-6"
                />
              </a>
              <a
                href="https://m.me/100001463683827"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/media/icons/messenger.webp"
                  alt="Messenger"
                  className="w-6 h-6"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-4 text-center">
          <p className="text-sm">
            &copy; 2024 Giaothong.info. Tất cả quyền được bảo lưu.
          </p>
        </div>
      </div>
    </div>
  );

  const editButtonText = isMobile
    ? isEditMode
      ? "THOÁT"
      : "SỬA"
    : isEditMode
    ? "THOÁT SỬA BIỂN BÁO"
    : "SỬA BIỂN BÁO";

  return (
    <div className="flex items-center space-x-2">
      {isLoggedIn && isEditMode && (
        <Tooltip title="Làm mới dữ liệu" arrow>
          <Button
            variant="contained"
            color="success"
            onClick={handleRefreshClick}
            disabled={isRefreshDisabled}
            className="mr-2"
            sx={{ height: "37px" }}
          >
            <RefreshIcon />
          </Button>
        </Tooltip>
      )}

      <Tooltip title={isEditMode ? "Thoát chế độ sửa" : "Thêm dữ liệu"} arrow>
        <Button
          variant="contained"
          color={isEditMode ? "warning" : "primary"}
          onClick={onEditButtonClick}
          startIcon={isEditMode ? <ExitToAppIcon /> : <AddIcon />}
          className="mr-2"
          sx={{ height: "37px" }}
          size="small"
        >
          {editButtonText}
        </Button>
      </Tooltip>

      {!isEditMode && (
        <>
          {isMobile ? (
            <Tooltip title="Chức năng" arrow>
              <Button
                variant="contained"
                color="primary"
                onClick={toggleDrawer(true)}
                sx={{
                  minWidth: "37px",
                  width: "37px",
                  height: "37px",
                  padding: 0,
                }}
              >
                <MenuIcon />
              </Button>
            </Tooltip>
          ) : (
            <div className="flex space-x-2">
              {menuItems.map((item, index) => (
                <Tooltip key={index} title={item.text} arrow>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={item.onClick}
                    startIcon={item.icon}
                    sx={{ height: "37px" }}
                  >
                    {item.text}
                  </Button>
                </Tooltip>
              ))}
            </div>
          )}
        </>
      )}

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: { width: "100%", height: "100%" },
        }}
      >
        {drawerContent}
      </Drawer>

      <FeedbackModal
        open={isFeedbackModalVisible}
        onClose={() => setIsFeedbackModalVisible(false)}
      />

      <CopyrightModal
        open={isCopyrightModalVisible}
        onClose={() => setIsCopyrightModalVisible(false)}
      />
    </div>
  );
};

export default ActionButtons;
