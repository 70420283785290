import React, { useState, useCallback, useRef } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import debounce from "lodash/debounce";
import { getMapSignById } from "../../services/api";

const filterOptions = [
  { value: 11, label: "Cấm đỗ", isFilter: true },
  { value: 12, label: "Cấm dừng cấm đỗ", isFilter: true },
  { value: 10, label: "Cấm ôtô", isFilter: true },
];

const animatedComponents = makeAnimated();

const SearchBar = ({
  onFocus,
  userPosition,
  onSearchStateChange,
  onSelect,
  onClear,
  mapRef,
  onLocationSelect,
  onFilterChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const searchRef = useRef(null);
  const provider = new OpenStreetMapProvider({
    params: {
      "accept-language": "vi",
      countrycodes: "vn",
    },
  });

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const performSearch = async (value) => {
    if (value.trim() === "") {
      return filterOptions;
    }

    try {
      const locationResults = await provider.search({ query: value });
      let locationOptions = locationResults.slice(0, 5).map((result) => ({
        value: { lat: result.y, lng: result.x },
        label: result.label,
        isLocation: true,
      }));

      if (userPosition) {
        locationOptions = locationOptions.map((option) => ({
          ...option,
          distance: calculateDistance(
            userPosition[0],
            userPosition[1],
            option.value.lat,
            option.value.lng
          ),
        }));
        locationOptions.sort((a, b) => a.distance - b.distance);
      }

      if (/^\d+$/.test(value)) {
        try {
          const signResult = await getMapSignById(parseInt(value));
          if (signResult) {
            locationOptions.unshift({
              value: {
                lat: signResult.latitude,
                lng: signResult.longitude,
                id: signResult.id,
              },
              label: `Biển báo ID: ${signResult.id}`,
              isLocation: true,
              isSign: true,
            });
          }
        } catch (error) {
          console.error("Lỗi khi tìm kiếm biển báo:", error);
        }
      }

      const matchingFilters = filterOptions.filter((filter) =>
        filter.label.toLowerCase().includes(value.toLowerCase())
      );

      return [...locationOptions, ...matchingFilters];
    } catch (error) {
      console.error("Lỗi tìm kiếm:", error);
      return [];
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (inputValue, callback) => {
      const results = await performSearch(inputValue);
      callback(results);
    }, 600),
    [userPosition]
  );

  const loadOptions = (inputValue, callback) => {
    setInputValue(inputValue);
    debouncedSearch(inputValue, callback);
  };

  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      if (newValue[newValue.length - 1]?.isLocation) {
        const location = newValue[newValue.length - 1];
        onLocationSelect(location.value);
        onSelect({ label: location.label, ...location.value });
        setSelectedOptions(newValue.filter((option) => !option.isLocation));
      } else {
        setSelectedOptions(newValue);
        const filterValues = newValue
          .filter((option) => option.isFilter)
          .map((filter) => filter.value);
        onFilterChange(filterValues);
      }
    } else if (
      actionMeta.action === "remove-value" ||
      actionMeta.action === "pop-value"
    ) {
      setSelectedOptions(newValue);
      const filterValues = newValue
        .filter((option) => option.isFilter)
        .map((filter) => filter.value);
      onFilterChange(filterValues);
    } else if (actionMeta.action === "clear") {
      setSelectedOptions([]);
      onFilterChange([]);
      onClear();
    }
  };

  const handleInputChange = (newValue, actionMeta) => {
    if (
      actionMeta.action !== "input-blur" &&
      actionMeta.action !== "menu-close"
    ) {
      setInputValue(newValue);
    }
  };

  return (
    <AsyncSelect
      ref={searchRef}
      isMulti
      cacheOptions
      defaultOptions={filterOptions}
      loadOptions={loadOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onFocus={() => {
        onFocus();
        onSearchStateChange(true);
      }}
      inputValue={inputValue}
      placeholder="Tìm kiếm"
      noOptionsMessage={() => "Không tìm thấy kết quả"}
      value={selectedOptions}
      formatOptionLabel={({ label, distance, isLocation, isSign }) => (
        <div className="flex items-center">
          {isSign && <span className="mr-2">🚦</span>}
          <div>
            <div>{label}</div>
            {isLocation && distance && (
              <div className="text-xs text-gray-500">
                {distance.toFixed(1)} km
              </div>
            )}
          </div>
        </div>
      )}
      components={animatedComponents}
      isClearable
      className="w-full bg-white rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
    />
  );
};

export default SearchBar;
