import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Avatar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../hooks/user/useAuth";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { usePermission } from "../../../hooks/user/usePermission";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getFromIndexedDB } from "../../../utils/indexedDB";
import CircleIcon from "@mui/icons-material/Circle";
import { keyframes } from "@mui/system";
import MapIcon from "@mui/icons-material/Map";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import CopyrightIcon from "@mui/icons-material/Copyright";

const Header = () => {
  const { isLoggedIn, logout, user } = useAuth();
  const navigate = useNavigate();
  const { permissions } = usePermission();
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const fetchUserData = async () => {
      if (isLoggedIn) {
        try {
          const data = await getFromIndexedDB("userData");
          if (data) {
            setUserData(data);
          }
        } catch (error) {
          console.error("Lỗi khi lấy dữ liệu người dùng:", error);
        }
      }
    };

    fetchUserData();
  }, [isLoggedIn]);

  const getAvatarSrc = (avatarPath) => {
    if (!avatarPath) return null;
    return avatarPath.startsWith("/media/avatars")
      ? avatarPath
      : `/media/avatars/${avatarPath}`;
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/home");
    } catch (error) {
      console.error("Lỗi khi đăng xuất:", error);
    }
  };

  const handleContactClick = (e) => {
    e.preventDefault();
    navigate("/home/contact");
  };

  const handleDataClick = (e) => {
    e.preventDefault();
    navigate("/home/data");
  };

  const handleCopyrightClick = (e) => {
    e.preventDefault();
    navigate("/home/copyrights");
  };

  const handleHomeClick = (e) => {
    e.preventDefault();
    navigate("/home");
  };

  const loginUrl = `/login?redirect=${encodeURIComponent("/home")}`;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate("/user");
    handleMenuClose();
  };

  const handleAdminClick = () => {
    navigate("/admin");
    handleMenuClose();
  };

  // Thêm hàm xử lý khi di chuột qua
  const handleMenuHover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const blinkAnimation = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  `;

  const menuItems = [
    { text: "Bản đồ số", href: "/", icon: <MapIcon /> },
    {
      text: "Dữ liệu",
      onClick: handleDataClick,
      icon: <DataUsageIcon />,
      additionalIcon: (
        <CircleIcon
          sx={{
            color: "#00712D",
            animation: `${blinkAnimation} 1s infinite`,
            fontSize: "0.8rem",
            marginLeft: "4px",
            verticalAlign: "super",
          }}
        />
      ),
    },
    {
      text: "Liên hệ",
      onClick: handleContactClick,
      icon: <ContactSupportIcon />,
    },
    {
      text: "Bản quyền",
      onClick: handleCopyrightClick,
      icon: <CopyrightIcon />,
    },
  ];

  const drawerContent = (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-2 bg-gray-800">
        <IconButton onClick={toggleDrawer(false)} className="text-white">
          <CloseIcon />
        </IconButton>
        <img
          src="/media/logos/1728034380491.png"
          alt="Logo"
          className="h-12 w-auto mx-auto"
          href="/home"
        />
        <div className="w-10"></div> {/* Phần tử trống để cân bằng layout */}
      </div>
      <Divider />
      <List className="flex-grow px-4">
        {isLoggedIn ? (
          <ListItem
            button
            onClick={() => {
              navigate("/user");
              setDrawerOpen(false);
            }}
          >
            <Avatar
              src={
                getAvatarSrc(userData?.avatar) || getAvatarSrc(user.avatarUrl)
              }
              alt={userData?.full_name || user.username}
              sx={{ width: 56, height: 56, marginRight: 2 }}
            />
            <div className="flex-grow">
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {userData?.full_name || user.username}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Thiết lập tài khoản
              </Typography>
            </div>
            <ArrowForwardIosIcon />
          </ListItem>
        ) : (
          <ListItem
            button
            onClick={() => {
              navigate(loginUrl);
              setDrawerOpen(false);
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText
              primary="Đăng nhập / Tạo tài khoản"
              primaryTypographyProps={{
                variant: "h6",
                style: { fontWeight: "bold" },
              }}
            />
            <ArrowForwardIosIcon />
          </ListItem>
        )}
        <Divider />
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              onClick={() => {
                if (item.onClick) {
                  item.onClick();
                } else if (item.href) {
                  navigate(item.href);
                }
                setDrawerOpen(false);
              }}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  variant: "h6",
                  style: { fontWeight: "bold" },
                }}
              />
            </ListItem>
            {index < menuItems.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
      <div className="mt-auto p-4 bg-gray-800 text-white">
        <div className="grid grid-cols-1 gap-4">
          <div>
            <h3 className="text-lg font-semibold mb-2">Liên kết nhanh</h3>
            <ul className="space-y-1">
              <li>
                <a href="/" className="text-sm">
                  Bản đồ biển báo giao thông
                </a>
              </li>
              <li>
                <a href="/home/data" className="text-sm">
                  Trang dữ liệu thống kê
                </a>
              </li>
              <li>
                <a href="/home/contact" className="text-sm">
                  Trang liên hệ
                </a>
              </li>
              <li>
                <a href="/home/copyrights" className="text-sm">
                  Thông tin bản quyền
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Thông tin liên hệ</h3>
            <p className="text-sm">Email: info@giaothong.info</p>
            <div className="flex space-x-4 mt-2">
              <a
                href="https://zalo.me/0938871357"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/media/icons/zalo.png"
                  alt="Zalo"
                  className="w-6 h-6"
                />
              </a>
              <a
                href="https://t.me/minhhoang_1105"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/media/icons/telegram.png"
                  alt="Telegram"
                  className="w-6 h-6"
                />
              </a>
              <a
                href="https://m.me/100001463683827"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/media/icons/messenger.webp"
                  alt="Messenger"
                  className="w-6 h-6"
                />
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Theo dõi chúng tôi</h3>
            {/* Thêm các biểu tượng mạng xã hội ở đây nếu cần */}
          </div>
        </div>
        <div className="mt-4 text-center">
          <p className="text-sm">
            &copy; 2024 Giaothong.info. Tất cả quyền được bảo lưu.
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <header className="bg-white shadow-md">
      <nav className="container mx-auto px-6 py-2">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2 md:space-x-6">
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <a
              href="/home"
              className="text-xl font-bold no-underline"
              onClick={handleHomeClick}
            >
              <div className="bg-black p-2 rounded">
                <img
                  src="/media/logos/1728034380491.png"
                  alt="Logo"
                  className="h-8 w-auto md:h-12"
                />
              </div>
            </a>
            <div className="hidden md:flex md:items-center md:space-x-6">
              {menuItems.map((item, index) => (
                <Button
                  key={index}
                  href={item.href}
                  color="inherit"
                  onClick={item.onClick}
                  sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
                  startIcon={item.icon}
                >
                  {item.text}
                  {item.additionalIcon}
                </Button>
              ))}
            </div>
          </div>
          <div>
            {isLoggedIn ? (
              <>
                <Button
                  ref={buttonRef}
                  onMouseEnter={handleMenuHover}
                  color="inherit"
                  variant="contained"
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Tài khoản
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  MenuListProps={{ onMouseLeave: handleMenuClose }}
                  PaperProps={{
                    style: {
                      width: buttonRef.current
                        ? buttonRef.current.offsetWidth
                        : "auto",
                      marginTop: "4px", // Tương đương với mt-1 trong Tailwind
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem onClick={handleProfileClick}>
                    <AccountCircleIcon sx={{ mr: 1 }} /> Hồ sơ
                  </MenuItem>
                  {permissions.canAccessAdminPanel && (
                    <MenuItem onClick={handleAdminClick}>
                      <AdminPanelSettingsIcon sx={{ mr: 1 }} /> Quản trị
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleLogout}>
                    <ExitToAppIcon sx={{ mr: 1 }} /> Đăng xuất
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                href={loginUrl}
                startIcon={<LoginIcon />}
                size="small"
                sx={{ fontSize: { xs: "0.75rem", md: "0.875rem" } }}
              >
                Đăng nhập
              </Button>
            )}
          </div>
        </div>
      </nav>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: { width: "100%", height: "100%" },
        }}
      >
        {drawerContent}
      </Drawer>
    </header>
  );
};

export default Header;
