import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { CircularProgress } from "@mui/material";

const HomePage = lazy(() => import("./homepage/HomePage"));
const ContactPage = lazy(() => import("./childpages/ContactPage"));
const CopyrightPage = lazy(() => import("./childpages/CopyrightPage"));
const DataPage = lazy(() => import("./childpages/DataPage"));

const LoadingFallback = () => (
  <div className="flex items-center justify-center h-screen">
    <CircularProgress />
  </div>
);

const LandingPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="copyrights" element={<CopyrightPage />} />
          <Route path="data" element={<DataPage />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
};

export default LandingPage;
