import React, { useState, useEffect, useMemo, useRef } from "react";
import { useAuth } from "../../hooks/user/useAuth";
import { usePermission } from "../../hooks/user/usePermission";
import { useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import {
  cilUser,
  cilSettings,
  cilShieldAlt,
  cilAccountLogout,
} from "@coreui/icons";
import { SERVER_URL } from "../../config";
import { getFromIndexedDB, removeFromIndexedDB } from "../../utils/indexedDB";
import useUserData from "../../hooks/user/useUserData";

const Avatar = ({ className }) => {
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const { permissions } = usePermission();
  const [userId, setUserId] = useState(null);
  const { userData, loading } = useUserData(userId);
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef(null);
  const avatarRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const id = await getFromIndexedDB("userId");
      setUserId(id);
    };

    if (isLoggedIn) {
      fetchUserId();
    }
  }, [isLoggedIn]);

  const avatarUrl = useMemo(() => {
    if (isLoggedIn && userData && userData.avatar) {
      return userData.avatar.startsWith("http")
        ? userData.avatar
        : `${SERVER_URL}/media/avatars/${userData.avatar}`;
    }
    return `${SERVER_URL}/media/avatars/default_avatar.png`;
  }, [isLoggedIn, userData]);

  const handleLogout = async () => {
    try {
      await removeFromIndexedDB("token");
      await removeFromIndexedDB("userId");
      await removeFromIndexedDB(`userData`);
      await removeFromIndexedDB("permissions");
      logout();
      navigate("/");
    } catch (error) {
      console.error("Lỗi khi đăng xuất:", error);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 300); // 300ms delay
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        avatarRef.current &&
        !avatarRef.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div
      className={`relative ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={avatarRef}
        className="w-10 h-10 rounded-full overflow-hidden cursor-pointer border-2 border-white shadow-md hover:shadow-lg transition-transform duration-200 ease-in-out hover:-translate-y-0.5"
      >
        <img
          src={avatarUrl}
          alt="Avatar người dùng"
          className="w-full h-full object-cover"
        />
      </div>
      {isLoggedIn && isOpen && (
        <div
          ref={menuRef}
          className="absolute right-0 mt-2 w-36 bg-white rounded-md shadow-lg z-10"
        >
          <button
            onClick={() => handleNavigate("/user")}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            <CIcon icon={cilUser} className="mr-2" /> Hồ sơ
          </button>
          <button
            onClick={() => handleNavigate("/user/settings")}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            <CIcon icon={cilSettings} className="mr-2" /> Cài đặt
          </button>
          {permissions.canAccessAdminPanel && (
            <button
              onClick={() => handleNavigate("/admin")}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              <CIcon icon={cilShieldAlt} className="mr-2" /> Quản trị
            </button>
          )}
          <button
            onClick={handleLogout}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            <CIcon icon={cilAccountLogout} className="mr-2" /> Đăng xuất
          </button>
        </div>
      )}
    </div>
  );
};

export default Avatar;
