import React, { useMemo } from "react";
import RoundSignComponent from "./RoundSignComponent";
import TriangleSignComponent from "./TriangleSignComponent";
import RectangleSignComponent from "./RectangleSignComponent";
import LaneDirectionSignComponent from "./LaneDirectionSignComponent";
import TrafficLaneSignComponent from "./TrafficLaneSignComponent";
import SquareSignComponent from "./SquareSignComponent";
import FlippedTriangleSignComponent from "./FlippedTriangleSignComponent";
import HeightLimitTriangleSignComponent from "./HeightLimitTriangleSignComponent";
import HeightLimitRoundSignComponent from "./HeightLimitRoundSignComponent";
import WidthLimitRoundSignComponent from "./WidthLimitRoundSignComponent";

const SignPreview = React.memo(({ sign, icons = [], size = 100 }) => {
  const signTypeId = useMemo(
    () => parseInt(sign?.sign_type_id || sign?.sign_id),
    [sign]
  );

  const getCrossType = useMemo(() => {
    if (!sign?.has_diagonal_stripe) return null;
    switch (sign?.stripe_direction) {
      case "left-to-right":
        return "redCrossLeft";
      case "right-to-left":
        return "redCrossRight";
      case "both":
        return "redCrossBoth";
      case "parallel":
        return "multiBlackCross";
      default:
        return null;
    }
  }, [sign]);

  const getContent = useMemo(() => {
    if (sign?.content_type === "Icon" && sign?.icon_id) {
      const icon = icons.find((i) => i.id === parseInt(sign.icon_id));
      if (icon && icon.image_path) {
        return (
          <img
            src={`/media/icons/${icon.image_path}`}
            alt={icon.icon_name}
            style={{ maxWidth: "80%", maxHeight: "80%", objectFit: "contain" }}
            loading="lazy"
          />
        );
      }
    }
    return sign?.content_number || "";
  }, [sign, icons]);

  const commonProps = useMemo(
    () => ({
      size: size,
      backgroundColor: sign?.background_color || "white",
      content: getContent,
      borderColor: sign?.has_border ? sign?.border_color : "transparent",
      contentColor: sign?.number_color || "black",
      contentStrokeColor: sign?.number_has_border
        ? sign?.number_border_color
        : "transparent",
      contentStrokeWidth: sign?.number_has_border ? 2 : 0,
      isIcon: sign?.content_type === "Icon",
      crossType: getCrossType,
    }),
    [sign, size, getContent, getCrossType]
  );

  const specialSignSize = useMemo(
    () => ({
      width: size * 1.5,
      height: size * 2.5,
    }),
    [size]
  );

  const SignComponent = useMemo(() => {
    if (!sign) return null;
    switch (signTypeId) {
      case 1:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
        return RoundSignComponent;
      case 2:
        return TriangleSignComponent;
      case 3:
        return RectangleSignComponent;
      case 5:
        return LaneDirectionSignComponent;
      case 6:
        return TrafficLaneSignComponent;
      case 7:
        return SquareSignComponent;
      case 13:
        return FlippedTriangleSignComponent;
      case 14:
        return HeightLimitTriangleSignComponent;
      case 15:
        return HeightLimitRoundSignComponent;
      case 16:
        return WidthLimitRoundSignComponent;
      default:
        return RectangleSignComponent;
    }
  }, [signTypeId]);

  if (!sign) {
    return null;
  }

  if (isNaN(signTypeId)) {
    console.error("Invalid sign_type_id:", sign.sign_type_id);
    return null;
  }

  return (
    SignComponent && <SignComponent {...commonProps} {...specialSignSize} />
  );
});

export default SignPreview;
