import React from "react";
import RoundSignComponent from "./RoundSignComponent";

const TrafficLaneSignComponent = ({
  lanes = 3,
  laneSeperatorType = "solid",
  laneSeperatorColor = "white",
  backgroundColor = "blue",
  borderColor = "white",
  crossType = "none",
  laneWidth = 100,
  laneHeight = 200,
  hasSpeedLimits = true,
  speedLimits = [],
  vehiclesPerLane = [],
  vehicleIcons = [],
}) => {
  // Tính toán kích thước tổng thể của biển báo
  const width = lanes * laneWidth;
  const height = laneHeight;
  const borderWidth = Math.max(2, Math.floor(width * 0.02));
  const speedLimitSize = 60; // Kích thước của biển báo giới hạn tốc độ

  // Hàm vẽ các làn đường
  const renderLanes = () => {
    return Array(lanes)
      .fill()
      .map((_, index) => (
        <g key={index}>
          <rect
            x={index * laneWidth}
            y={0}
            width={laneWidth}
            height={height}
            fill="transparent"
            stroke={borderColor}
            strokeWidth={1}
          />
          {hasSpeedLimits && speedLimits[index] && (
            <foreignObject
              x={index * laneWidth + (laneWidth - speedLimitSize) / 2}
              y={10}
              width={speedLimitSize}
              height={speedLimitSize}
            >
              <RoundSignComponent
                size={speedLimitSize}
                backgroundColor="white"
                content={speedLimits[index]}
                borderColor="#e12321"
                contentColor="black"
              />
            </foreignObject>
          )}
          {renderVehicleIcons(index)}
        </g>
      ));
  };

  // Hàm vẽ biểu tượng phương tiện cho mỗi làn
  const renderVehicleIcons = (laneIndex) => {
    const icons = vehicleIcons[laneIndex] || [];
    const iconSize = 40;
    const startY = hasSpeedLimits ? speedLimitSize + 20 : 10;
    const spacing =
      (height - startY - icons.length * iconSize) / (icons.length + 1);

    return icons.map((icon, index) => (
      <foreignObject
        key={index}
        x={laneIndex * laneWidth + (laneWidth - iconSize) / 2}
        y={startY + spacing * (index + 1) + iconSize * index}
        width={iconSize}
        height={iconSize}
      >
        <img
          src={`/media/icons/${icon.image_path}`}
          alt={icon.icon_name}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </foreignObject>
    ));
  };

  // Hàm vẽ vạch phân chia làn đường
  const renderLaneSeparators = () => {
    return Array(lanes - 1)
      .fill()
      .map((_, index) => (
        <line
          key={index}
          x1={(index + 1) * laneWidth}
          y1={0}
          x2={(index + 1) * laneWidth}
          y2={height}
          stroke={laneSeperatorColor}
          strokeWidth={2}
          strokeDasharray={laneSeperatorType === "dash" ? "5,5" : ""}
        />
      ));
  };

  // Hàm vẽ vạch chéo (nếu có)
  const renderCross = () => {
    if (crossType === "none") return null;

    const offset = borderWidth;
    return (
      <line
        x1={offset}
        y1={
          crossType === "left" || crossType === "both"
            ? height - offset
            : offset
        }
        x2={width - offset}
        y2={
          crossType === "right" || crossType === "both"
            ? height - offset
            : offset
        }
        stroke="#e12321"
        strokeWidth={borderWidth}
        strokeLinecap="round"
      />
    );
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
    >
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
      />
      {renderLanes()}
      {renderLaneSeparators()}
      {renderCross()}
    </svg>
  );
};

export default TrafficLaneSignComponent;
