import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import CIcon from "@coreui/icons-react";
import { cilBell } from "@coreui/icons";
import NotificationList from "./NotificationList";
import {
  getNotifications,
  markNotificationAsRead as markNotificationAsReadAPI,
} from "../../services/api";
import { removeFromIndexedDB } from "../../utils/indexedDB";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/user/useAuth";

// Hook tùy chỉnh để tạo CSS animation
const useRingingAnimation = (unreadCount) => {
  return `
    @keyframes ringing {
      0%, 100% { transform: rotate(0deg); }
      5% { transform: rotate(15deg); }
      10% { transform: rotate(-15deg); }
      15% { transform: rotate(15deg); }
      20% { transform: rotate(-15deg); }
      25% { transform: rotate(0deg); }
    }
    .bell-icon-wrapper {
      animation: ${unreadCount > 0 ? "ringing 2s infinite" : "none"};
      transform-origin: 50% 0;
    }
  `;
};

const NotificationComponent = ({
  initialNotifications = [],
  className = "",
  position = { top: "100%", right: "0" },
  renderCustomIcon,
  customListComponent: CustomListComponent,
  onNotificationsUpdated,
}) => {
  const [notifications, setNotifications] = useState(initialNotifications);
  const [isNotificationListVisible, setIsNotificationListVisible] =
    useState(false);
  const notificationTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await removeFromIndexedDB("token");
      await removeFromIndexedDB("userId");
      await removeFromIndexedDB("user");
      await removeFromIndexedDB(`userData`);
      await removeFromIndexedDB("permissions");
      logout();
      navigate("/");
    } catch (error) {
      console.error("Lỗi khi đăng xuất:", error);
    }
  };

  const fetchNotifications = useCallback(async () => {
    try {
      const fetchedNotifications = await getNotifications();
      setNotifications(fetchedNotifications);
      if (onNotificationsUpdated) {
        onNotificationsUpdated(fetchedNotifications);
      }
    } catch (error) {
      console.error("Lỗi khi lấy thông báo:", error);
      await handleLogout();
    }
  }, [onNotificationsUpdated]);

  useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000); // Cập nhật mỗi phút

    return () => clearInterval(intervalId);
  }, [fetchNotifications]);

  const unreadNotificationsCount = useMemo(() => {
    return notifications.filter((notification) => !notification.is_read).length;
  }, [notifications]);

  const ringingAnimationStyle = useRingingAnimation(unreadNotificationsCount);

  const handleNotificationMouseEnter = () => {
    clearTimeout(notificationTimeoutRef.current);
    setIsNotificationListVisible(true);
  };

  const handleNotificationMouseLeave = () => {
    notificationTimeoutRef.current = setTimeout(() => {
      setIsNotificationListVisible(false);
    }, 300);
  };

  const handleNotificationsUpdated = useCallback(
    (updatedNotifications) => {
      setNotifications(updatedNotifications);
      if (onNotificationsUpdated) {
        onNotificationsUpdated(updatedNotifications);
      }
    },
    [onNotificationsUpdated]
  );

  const markNotificationAsRead = useCallback(
    async (notificationId) => {
      try {
        await markNotificationAsReadAPI(notificationId);
        // Fetch lại danh sách thông báo sau khi đánh dấu đã đọc
        await fetchNotifications();
      } catch (error) {
        console.error("Lỗi khi đánh dấu thông báo đã đọc:", error);
      }
    },
    [fetchNotifications]
  );

  const BellIcon = ({ unreadCount }) => {
    return (
      <div className="bell-icon-wrapper relative">
        <CIcon icon={cilBell} size="lg" className="text-gray-600" />
        {unreadCount > 0 && (
          <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
            {unreadCount}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className={`relative ${className}`}>
      <div
        className="cursor-pointer"
        onMouseEnter={handleNotificationMouseEnter}
        onMouseLeave={handleNotificationMouseLeave}
      >
        {renderCustomIcon ? (
          renderCustomIcon(unreadNotificationsCount)
        ) : (
          <BellIcon unreadCount={unreadNotificationsCount} />
        )}
      </div>

      {CustomListComponent ? (
        <CustomListComponent
          notifications={notifications}
          markNotificationAsRead={markNotificationAsRead}
          isVisible={isNotificationListVisible}
          onMouseEnter={handleNotificationMouseEnter}
          onMouseLeave={handleNotificationMouseLeave}
          position={position}
          onNotificationsUpdated={handleNotificationsUpdated}
        />
      ) : (
        <NotificationList
          notifications={notifications}
          markNotificationAsRead={markNotificationAsRead}
          isVisible={isNotificationListVisible}
          onMouseEnter={handleNotificationMouseEnter}
          onMouseLeave={handleNotificationMouseLeave}
          position={position}
          onNotificationsUpdated={handleNotificationsUpdated}
        />
      )}
      <style>{ringingAnimationStyle}</style>
    </div>
  );
};

export default NotificationComponent;
