import React from "react";

const RectangleSignComponent = ({
  size = 100,
  backgroundColor,
  content,
  borderColor,
  contentColor,
  contentStrokeColor,
  contentStrokeWidth,
  crossType,
  isIcon = false,
}) => {
  // Để thay đổi kích thước của biển báo, bạn cần điều chỉnh các biến sau:
  const width = typeof size === "number" && !isNaN(size) ? size : 100; // Chiều rộng của biển báo
  const height = width * 1.5; // Thay đổi 1.5 thành tỉ lệ mong muốn

  // Bạn có thể thay đổi tỉ lệ này nếu muốn biển báo có hình dạng khác
  // Ví dụ: const height = width * 2; // Để có tỉ lệ 2:1
  const fontSize = Math.floor(width * 0.4);

  // Điều chỉnh độ dày của viền và vạch chéo
  const borderWidth = Math.max(2, Math.floor(width * 0.05)); // Có thể điều chỉnh hệ số này để thay đổi độ dày

  // Hàm vẽ vạch chéo đen song song
  const renderMultiBlackCross = () => {
    const lineCount = 5;
    const gap = width / 25;
    const startX = width * 0.2;
    const endX = width * 0.8;
    const startY = height * 0.2;
    const endY = height * 0.8;

    return (
      <>
        {[...Array(lineCount)].map((_, index) => {
          const offset = index * gap;
          return (
            <line
              key={index}
              x1={startX + offset}
              y1={startY}
              x2={startX + offset}
              y2={endY}
              stroke="black"
              strokeWidth={borderWidth / 2}
              strokeLinecap="round"
            />
          );
        })}
      </>
    );
  };

  // Hàm vẽ vạch chéo đỏ
  const renderRedCross = (direction) => {
    // Tính toán khoảng cách để thu ngắn vạch chéo
    const offset = borderWidth; // Có thể điều chỉnh giá trị này để thay đổi độ dài của vạch chéo

    if (direction === "left" || direction === "both") {
      return (
        <line
          x1={offset}
          y1={height - offset}
          x2={width - offset}
          y2={offset}
          stroke="#e12321"
          strokeWidth={borderWidth}
          strokeLinecap="round" // Thay đổi lại thành "butt"
        />
      );
    } else if (direction === "right") {
      return (
        <line
          x1={offset}
          y1={offset}
          x2={width - offset}
          y2={height - offset}
          stroke="#e12321"
          strokeWidth={borderWidth}
          strokeLinecap="round" // Thay đổi lại thành "butt"
        />
      );
    }
  };

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: "relative",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
      >
        {/* Hình chữ nhật */}
        <rect
          x={borderWidth / 2}
          y={borderWidth / 2}
          width={width - borderWidth}
          height={height - borderWidth}
          fill={backgroundColor}
          stroke={borderColor}
          strokeWidth={borderWidth}
        />
        {/* Nội dung */}
        {isIcon ? (
          <foreignObject x="10%" y="20%" width="80%" height="60%">
            <div
              xmlns="http://www.w3.org/1999/xhtml"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: contentColor,
                fontSize: `${fontSize}px`,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {content}
            </div>
          </foreignObject>
        ) : (
          <text
            x={width / 2}
            y={height / 2}
            fontSize={fontSize}
            textAnchor="middle"
            dominantBaseline="central"
            fill={contentColor}
            stroke={contentStrokeColor}
            strokeWidth={contentStrokeWidth}
            fontFamily="Arial"
            fontWeight="bold"
          >
            {content}
          </text>
        )}
        {/* Vạch chéo */}
        {crossType === "multiBlackCross" && renderMultiBlackCross()}
        {crossType === "redCrossLeft" && renderRedCross("left")}
        {crossType === "redCrossRight" && renderRedCross("right")}
        {crossType === "redCrossBoth" && (
          <>
            {renderRedCross("left")}
            {renderRedCross("right")}
          </>
        )}
      </svg>
    </div>
  );
};

export default RectangleSignComponent;
