import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import store from "./store";
import AppContent from "./components/AppContent";
import { useUserTracking } from "./hooks/analytics/useUserTracking";

// Tên dự án mới
const PROJECT_NAME = "Cộng đồng chia sẻ và Bản đồ biển báo giao thông Việt Nam";

function App() {
  useUserTracking();
  return (
    <Provider store={store}>
      <Router>
        {/* Thêm các thẻ meta chuẩn SEO */}
        <Helmet>
          <title>{PROJECT_NAME}</title>
          <meta
            name="description"
            content="Hệ thống quản lý, tra cứu và bản đồ biển báo giao thông hiện đại của Việt Nam, cung cấp thông tin chính xác và cập nhật về vị trí và ý nghĩa các biển báo trên đường."
          />
          <meta
            name="keywords"
            content="biển báo giao thông, quản lý biển báo, an toàn đường bộ, giao thông, hệ thống quản lý, Việt Nam, cộng đồng, bản đồ biển báo"
          />
          <meta property="og:title" content={PROJECT_NAME} />
          <meta
            property="og:description"
            content="Hệ thống quản lý, tra cứu và bản đồ biển báo giao thông hiện đại của Việt Nam, cung cấp thông tin chính xác và cập nhật về vị trí và ý nghĩa các biển báo trên đường."
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={PROJECT_NAME} />
          <meta
            name="twitter:description"
            content="Hệ thống quản lý, tra cứu và bản đồ biển báo giao thông hiện đại của Việt Nam, cung cấp thông tin chính xác và cập nhật về vị trí và ý nghĩa các biển báo trên đường."
          />
          {/* Thẻ link cho sitemap */}
          <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        </Helmet>
        <AppContent />
      </Router>
    </Provider>
  );
}

export default App;
