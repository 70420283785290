import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import VerifyEmail from "../users/VerifyEmail";
import ForgetPasswordForm from "../users/ForgetPasswordForm";

const EmailVerificationHandler = () => {
  const [showForgetPasswordForm, setShowForgetPasswordForm] = useState(false);
  const [forgetPasswordData, setForgetPasswordData] = useState(null);
  const location = useLocation();

  const handleVerificationSuccess = (responseData) => {
    const searchParams = new URLSearchParams(location.search);
    const isResetPassword = searchParams.get("isResetPassword") === "true";
    const token = searchParams.get("token");

    if (isResetPassword) {
      setForgetPasswordData({
        isInitialState: false,
        email: responseData.email,
        token: token,
      });
      setShowForgetPasswordForm(true);
    }
  };

  if (showForgetPasswordForm) {
    return <ForgetPasswordForm {...forgetPasswordData} />;
  }

  return <VerifyEmail onVerificationSuccess={handleVerificationSuccess} />;
};

export default EmailVerificationHandler;
