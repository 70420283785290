// LaneDirectionSignComponent.js
import React from "react";

const LaneDirectionSignComponent = ({
  lanes = 3,
  laneSeperatorType = "solid",
  laneIcons = [],
  backgroundColor = "blue",
  borderColor = "white",
  laneSeperatorColor = "white", // Thêm màu cho vạch phân chia làn đường
  crossType = "none",
  laneWidth = 100,
  laneHeight = 150,
}) => {
  // Tính toán kích thước tổng thể của biển báo
  const width = lanes * laneWidth;
  const height = laneHeight;
  const borderWidth = Math.max(2, Math.floor(width * 0.02));

  // Hàm vẽ các làn đường
  const renderLanes = () => {
    return Array(lanes)
      .fill()
      .map((_, index) => (
        <g key={index}>
          <rect
            x={index * laneWidth}
            y={0}
            width={laneWidth}
            height={height}
            fill="transparent"
            stroke={borderColor}
            strokeWidth={1}
          />
          {laneIcons[index] && (
            <foreignObject
              x={index * laneWidth}
              y={0}
              width={laneWidth}
              height={height}
            >
              <div
                xmlns="http://www.w3.org/1999/xhtml"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={`/media/icons/${laneIcons[index].image_path}`}
                  alt={laneIcons[index].icon_name}
                  style={{
                    maxWidth: "80%",
                    maxHeight: "80%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </foreignObject>
          )}
        </g>
      ));
  };

  // Hàm vẽ vạch phân chia làn đường
  const renderLaneSeparators = () => {
    return Array(lanes - 1)
      .fill()
      .map((_, index) => (
        <line
          key={index}
          x1={(index + 1) * laneWidth}
          y1={0}
          x2={(index + 1) * laneWidth}
          y2={height}
          stroke={laneSeperatorColor} // Sử dụng màu vạch phân chia
          strokeWidth={2}
          strokeDasharray={laneSeperatorType === "dash" ? "5,5" : ""}
        />
      ));
  };

  // Hàm vẽ vạch chéo (nếu có)
  const renderCross = () => {
    if (crossType === "none") return null;

    const offset = borderWidth;
    return (
      <line
        x1={offset}
        y1={
          crossType === "left" || crossType === "both"
            ? height - offset
            : offset
        }
        x2={width - offset}
        y2={
          crossType === "right" || crossType === "both"
            ? height - offset
            : offset
        }
        stroke="#e12321"
        strokeWidth={borderWidth}
        strokeLinecap="round"
      />
    );
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
    >
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={borderWidth}
      />
      {renderLanes()}
      {renderLaneSeparators()}
      {renderCross()}
    </svg>
  );
};

export default LaneDirectionSignComponent;
