import React, { useMemo } from "react";

const RoundSignComponent = ({
  size = 100,
  backgroundColor,
  content,
  borderColor,
  contentColor,
  contentStrokeColor,
  contentStrokeWidth,
  crossType,
  isIcon = false,
}) => {
  const buttonSize = useMemo(
    () => (typeof size === "number" && !isNaN(size) ? size : 100),
    [size]
  );
  const fontSize = useMemo(() => Math.floor(buttonSize * 0.5), [buttonSize]);
  const borderWidth = useMemo(
    () => Math.max(3, Math.floor(buttonSize * 0.08)),
    [buttonSize]
  );

  const renderMultiBlackCross = useMemo(() => {
    const lineCount = 5;
    const gap = buttonSize / 25;
    const startX = buttonSize * 0.2;
    const endX = buttonSize * 0.8;
    const startY = buttonSize * 0.2;
    const endY = buttonSize * 0.8;

    return (
      <>
        {[...Array(lineCount)].map((_, index) => {
          const offset = index * gap;
          return (
            <line
              key={index}
              x1={startX + offset}
              y1={startY}
              x2={startX + offset}
              y2={endY}
              stroke="black"
              strokeWidth={borderWidth / 2}
              strokeLinecap="round"
            />
          );
        })}
      </>
    );
  }, [buttonSize, borderWidth]);

  const renderRedCross = useMemo(
    () => (direction) => {
      const startX = buttonSize * 0.15;
      const endX = buttonSize * 0.85;
      const startY = buttonSize * 0.15;
      const endY = buttonSize * 0.85;

      if (direction === "left" || direction === "both") {
        return (
          <line
            x1={startX}
            y1={endY}
            x2={endX}
            y2={startY}
            stroke="#e12321"
            strokeWidth={borderWidth}
            strokeLinecap="butt"
          />
        );
      } else if (direction === "right") {
        return (
          <line
            x1={startX}
            y1={startY}
            x2={endX}
            y2={endY}
            stroke="#e12321"
            strokeWidth={borderWidth}
            strokeLinecap="butt"
          />
        );
      }
    },
    [buttonSize, borderWidth]
  );

  return (
    <div
      style={{
        width: `${buttonSize}px`,
        height: `${buttonSize}px`,
        position: "relative",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${buttonSize} ${buttonSize}`}
        width={buttonSize}
        height={buttonSize}
      >
        <circle
          cx={buttonSize / 2}
          cy={buttonSize / 2}
          r={(buttonSize - borderWidth) / 2}
          fill={backgroundColor}
          stroke={borderColor}
          strokeWidth={borderWidth}
        />
        {isIcon ? (
          <foreignObject x="10%" y="10%" width="80%" height="80%">
            <div
              xmlns="http://www.w3.org/1999/xhtml"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: contentColor,
                fontSize: `${fontSize}px`,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {content}
            </div>
          </foreignObject>
        ) : (
          <text
            x={buttonSize / 2}
            y={buttonSize / 2}
            fontSize={fontSize}
            textAnchor="middle"
            dominantBaseline="central"
            fill={contentColor}
            stroke={contentStrokeColor}
            strokeWidth={contentStrokeWidth}
            fontFamily="Arial"
            fontWeight="bold"
          >
            {content}
          </text>
        )}
        {crossType === "multiBlackCross" && renderMultiBlackCross()}
        {crossType === "redCrossLeft" && renderRedCross("left")}
        {crossType === "redCrossRight" && renderRedCross("right")}
        {crossType === "redCrossBoth" && (
          <>
            {renderRedCross("left")}
            {renderRedCross("right")}
          </>
        )}
      </svg>
    </div>
  );
};

export default React.memo(RoundSignComponent);
