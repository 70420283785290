import React from "react";

const TriangleSignComponent = ({
  size = 100,
  backgroundColor,
  content,
  borderColor,
  contentColor,
  contentStrokeColor,
  contentStrokeWidth,
  crossType,
  isIcon = false,
}) => {
  const buttonSize = typeof size === "number" && !isNaN(size) ? size : 100;
  const fontSize = Math.floor(buttonSize * 0.15);
  const borderWidth = Math.max(3, Math.floor(buttonSize * 0.06));

  const halfSize = buttonSize / 2;
  const height = (Math.sqrt(3) / 2) * buttonSize;
  const points = `${halfSize},${borderWidth} ${borderWidth},${
    height - borderWidth
  } ${buttonSize - borderWidth},${height - borderWidth}`;

  const createCrossLine = (x1, y1, x2, y2, color, width) => (
    <line
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      stroke={color}
      strokeWidth={width}
      strokeLinecap="butt"
    />
  );

  const renderContent = () => {
    if (isIcon) {
      return (
        <foreignObject x="20%" y="30%" width="60%" height="50%">
          <div
            className="w-full h-full flex justify-center items-center text-center font-bold"
            style={{ color: contentColor, fontSize: `${fontSize}px` }}
          >
            {content}
          </div>
        </foreignObject>
      );
    } else {
      const words = content.split(" ");
      const firstLine = words.slice(0, Math.ceil(words.length / 2)).join(" ");
      const secondLine = words.slice(Math.ceil(words.length / 2)).join(" ");

      return (
        <>
          <text
            x={buttonSize / 2}
            y={buttonSize * 0.4}
            fontSize={fontSize}
            textAnchor="middle"
            dominantBaseline="central"
            fill={contentColor}
            stroke={contentStrokeColor}
            strokeWidth={contentStrokeWidth}
            className="font-bold"
          >
            {firstLine}
          </text>
          <text
            x={buttonSize / 2}
            y={buttonSize * 0.65}
            fontSize={fontSize}
            textAnchor="middle"
            dominantBaseline="central"
            fill={contentColor}
            stroke={contentStrokeColor}
            strokeWidth={contentStrokeWidth}
            className="font-bold"
          >
            {secondLine}
          </text>
        </>
      );
    }
  };

  const renderCross = () => {
    const startX = buttonSize * 0.2;
    const endX = buttonSize * 0.8;
    const startY = buttonSize * 0.3;
    const endY = buttonSize * 0.9;

    switch (crossType) {
      case "multiBlackCross":
        return [...Array(5)].map((_, index) => {
          const offset = index * (buttonSize / 25);
          return createCrossLine(
            startX + offset,
            startY,
            startX + offset,
            endY,
            "black",
            borderWidth / 2
          );
        });
      case "redCrossLeft":
        return createCrossLine(
          startX,
          endY,
          endX,
          startY,
          "#e12321",
          borderWidth
        );
      case "redCrossRight":
        return createCrossLine(
          startX,
          startY,
          endX,
          endY,
          "#e12321",
          borderWidth
        );
      case "redCrossBoth":
        return (
          <>
            {createCrossLine(
              startX,
              endY,
              endX,
              startY,
              "#e12321",
              borderWidth
            )}
            {createCrossLine(
              startX,
              startY,
              endX,
              endY,
              "#e12321",
              borderWidth
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="relative map-sign-preview triangle-sign"
      style={{
        width: `${buttonSize}px`,
        height: `${buttonSize}px`,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${buttonSize} ${buttonSize}`}
        width={buttonSize}
        height={buttonSize}
      >
        <polygon
          points={points}
          fill={backgroundColor}
          stroke={borderColor}
          strokeWidth={borderWidth}
        />
        {renderContent()}
        {renderCross()}
      </svg>
    </div>
  );
};

export default TriangleSignComponent;
