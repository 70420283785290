import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const [secondsLeft, setSecondsLeft] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsLeft((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(timer);
          navigate("/");
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div className="flex justify-center items-center min-h-screen w-full bg-gradient-to-r from-purple-600 to-blue-500 font-sans">
      <div className="bg-white p-8 rounded-lg shadow-md text-center max-w-md">
        <h1 className="text-4xl font-bold text-purple-600 mb-4">404</h1>
        <p className="text-lg text-gray-700 mb-6">Trang không tồn tại</p>
        <p className="text-sm text-gray-500 mb-4">
          Bạn sẽ được chuyển hướng về trang chủ sau {secondsLeft} giây
        </p>
        <button
          onClick={() => navigate("/")}
          className="px-4 py-2 bg-gradient-to-r from-purple-600 to-blue-500 text-white rounded hover:opacity-90 transition-opacity duration-300"
        >
          Quay về trang chủ ngay
        </button>
      </div>
    </div>
  );
};

export default NotFound;
