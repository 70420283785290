import { useState, useEffect, useCallback } from "react";
import {
  getFromIndexedDB,
  saveToIndexedDB,
  removeFromIndexedDB,
  clearOldestFromIndexedDB,
} from "../../utils/indexedDB";
import {
  getSigns,
  getSubsigns,
  getIcons,
  getSignTypes,
} from "../../services/api";

// Định nghĩa các store và thời gian hết hạn cache
const CACHE_STORES = {
  signs: "signs",
  subsigns: "subsigns",
  icons: "icons",
  signTypes: "signTypes",
};

const CACHE_EXPIRATION = 60 * 60 * 1000; // 60 phút
const MAX_CACHE_SIZE = 1000; // Giới hạn số lượng items trong mỗi cache
const MAX_CACHE_ENTRIES = 15; // Giới hạn số lượng entries trong mỗi cache store

const useSignsData = () => {
  const [data, setData] = useState({
    signs: null,
    subsigns: null,
    icons: null,
    signTypes: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Hàm clear cache
  const clearCache = async () => {
    await Promise.all(
      Object.values(CACHE_STORES).map((store) =>
        removeFromIndexedDB(store, null)
      )
    );
  };

  // Hàm merge dữ liệu
  const mergeData = (oldData, newData) => {
    const mergedMap = new Map();

    // Thêm dữ liệu cũ vào Map
    oldData.forEach((item) => mergedMap.set(item.id, item));

    // Cập nhật hoặc thêm dữ liệu mới
    newData.forEach((item) => mergedMap.set(item.id, item));

    // Chuyển Map thành mảng và giới hạn kích thước
    return Array.from(mergedMap.values()).slice(0, MAX_CACHE_SIZE);
  };

  // Hàm fetch và cache dữ liệu
  const fetchAndCacheData = useCallback(async (storeName, fetchFunction) => {
    try {
      const cachedData = await getFromIndexedDB(storeName);
      let newData;

      if (cachedData && Date.now() - cachedData.timestamp < CACHE_EXPIRATION) {
        newData = cachedData.data;
      } else {
        const freshData = await fetchFunction();

        if (cachedData && cachedData.data) {
          newData = mergeData(cachedData.data, freshData);
        } else {
          newData = freshData;
        }

        await saveToIndexedDB(storeName, {
          data: newData,
          timestamp: Date.now(),
        });

        // Kiểm tra và xóa cache cũ nếu vượt quá giới hạn
        const cacheEntries = await getFromIndexedDB(storeName);
        if (Object.keys(cacheEntries).length > MAX_CACHE_ENTRIES) {
          await clearOldestFromIndexedDB(storeName, MAX_CACHE_ENTRIES);
        }
      }

      return newData;
    } catch (error) {
      console.error(`Lỗi khi xử lý dữ liệu cho ${storeName}:`, error);
      throw error;
    }
  }, []);

  // Hàm fetch dữ liệu
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const [signsData, subsignsData, iconsData, signTypesData] =
        await Promise.all([
          fetchAndCacheData(CACHE_STORES.signs, getSigns),
          fetchAndCacheData(CACHE_STORES.subsigns, getSubsigns),
          fetchAndCacheData(CACHE_STORES.icons, getIcons),
          fetchAndCacheData(CACHE_STORES.signTypes, getSignTypes),
        ]);

      setData({
        signs: signsData,
        subsigns: subsignsData,
        icons: iconsData,
        signTypes: signTypesData,
      });
    } catch (err) {
      console.error("Lỗi khi tải dữ liệu:", err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [fetchAndCacheData]);

  // Hàm refreshData
  const refreshData = useCallback(async () => {
    await clearCache();
    await fetchData();
  }, [fetchData]);

  // Effect để fetch dữ liệu khi component mount và mỗi CACHE_EXPIRATION
  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, CACHE_EXPIRATION);
    return () => clearInterval(intervalId);
  }, [fetchData]);

  return { data, isLoading, error, refreshData };
};

export default useSignsData;
