import { useState, useEffect, useCallback } from "react";
import { getUserById } from "../../services/api";
import { getFromIndexedDB, saveToIndexedDB } from "../../utils/indexedDB";
import { debounce } from "lodash";

// Hook tùy chỉnh để lấy và quản lý dữ liệu người dùng
const useUserData = (userId) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Hàm fetch dữ liệu người dùng
  const fetchUserData = useCallback(async () => {
    if (!userId) {
      setLoading(false);
      return;
    }

    try {
      // Kiểm tra cache trong IndexedDB
      const cachedData = await getFromIndexedDB(`userData`);
      if (cachedData) {
        setUserData(cachedData);
        setLoading(false);
        return;
      }

      // Nếu không có cache, gọi API
      const data = await getUserById(userId);
      setUserData(data);

      // Lưu dữ liệu vào IndexedDB
      await saveToIndexedDB(`userData`, data);
    } catch (err) {
      console.error("Lỗi khi lấy thông tin người dùng:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  // Tạo phiên bản debounce của fetchUserData
  const debouncedFetchUserData = useCallback((callback) => {
    const debouncedFn = debounce(callback, 300);
    debouncedFn();
    return debouncedFn.cancel;
  }, []);

  useEffect(() => {
    setLoading(true); // Đặt loading thành true khi userId thay đổi
    const cancelDebounce = debouncedFetchUserData(fetchUserData);

    // Cleanup function
    return cancelDebounce;
  }, [userId, fetchUserData, debouncedFetchUserData]);

  // Hàm để cập nhật dữ liệu người dùng
  const updateUserData = useCallback((newData) => {
    setUserData(newData);
    saveToIndexedDB(`userData`, newData);
  }, []);

  // Bắt buộc cập nhật dữ liệu người dùng
  const forceUpdateUserData = useCallback(async (id) => {
    try {
      setLoading(true);
      const data = await getUserById(id);
      setUserData(data);
      await saveToIndexedDB(`userData`, data);
    } catch (err) {
      console.error("Lỗi khi cập nhật thông tin người dùng:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return { userData, loading, error, updateUserData, forceUpdateUserData };
};

export default useUserData;
