import React from "react";

const HeightLimitTriangleSignComponent = ({
  size = 100,
  backgroundColor,
  content,
  borderColor,
  contentColor,
  contentStrokeColor,
  contentStrokeWidth,
}) => {
  const buttonSize = typeof size === "number" && !isNaN(size) ? size : 100;
  const fontSize = Math.floor(buttonSize * 0.15);
  const borderWidth = Math.max(3, Math.floor(buttonSize * 0.06));

  const halfSize = buttonSize / 2;
  const height = (Math.sqrt(3) / 2) * buttonSize;
  const points = `${halfSize},${borderWidth} ${borderWidth},${
    height - borderWidth
  } ${buttonSize - borderWidth},${height - borderWidth}`;

  // Giảm kích thước của tam giác nhỏ
  const smallTriangleSize = buttonSize * 0.1; // Đã thay đổi từ 0.2
  const smallTriangleHeight = (Math.sqrt(3) / 2) * smallTriangleSize;

  const renderSmallTriangle = (isTop) => {
    // Điều chỉnh vị trí của tam giác để cách xa nhau hơn
    const yOffset = isTop
      ? buttonSize * 0.3 // Đã thay đổi từ 0.25
      : buttonSize * 0.75 - smallTriangleHeight; // Đã thay đổi từ 0.75

    // Lật ngược tam giác
    const points = isTop
      ? `${halfSize},${yOffset + smallTriangleHeight} ${
          halfSize - smallTriangleSize / 2
        },${yOffset} ${halfSize + smallTriangleSize / 2},${yOffset}`
      : `${halfSize},${yOffset} ${halfSize - smallTriangleSize / 2},${
          yOffset + smallTriangleHeight
        } ${halfSize + smallTriangleSize / 2},${yOffset + smallTriangleHeight}`;

    return (
      <polygon
        points={points}
        fill={contentColor} // Đã thay đổi từ backgroundColor
        stroke={contentColor} // Đã thay đổi từ borderColor
        strokeWidth={borderWidth / 2}
      />
    );
  };

  return (
    <div
      className="relative map-sign-preview height-limit-triangle-sign"
      style={{
        width: `${buttonSize}px`,
        height: `${buttonSize}px`,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${buttonSize} ${buttonSize}`}
        width={buttonSize}
        height={buttonSize}
      >
        <polygon
          points={points}
          fill={backgroundColor}
          stroke={borderColor}
          strokeWidth={borderWidth}
        />

        {renderSmallTriangle(true)}
        {renderSmallTriangle(false)}

        <text
          x={buttonSize / 2}
          y={buttonSize * 0.52}
          fontSize={fontSize}
          textAnchor="middle"
          dominantBaseline="central"
          fill={contentColor}
          stroke={contentStrokeColor}
          strokeWidth={contentStrokeWidth}
          className="font-bold"
        >
          {content}
        </text>
      </svg>
    </div>
  );
};

export default HeightLimitTriangleSignComponent;
