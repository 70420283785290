import React, { useState } from "react";
import { forgetPassword, sendResetPasswordEmail } from "../../services/api";
import CIcon from "@coreui/icons-react";
import {
  cilEnvelopeClosed,
  cilLockLocked,
  cilWarning,
  cilUser,
} from "@coreui/icons";
import { removeFromIndexedDB } from "../../utils/indexedDB";
import { useNavigate, Link } from "react-router-dom";

const ForgetPasswordForm = ({
  isInitialState = true,
  email = "",
  token = "",
}) => {
  const [formData, setFormData] = useState({
    email: email,
    newPassword: "",
    confirmNewPassword: "",
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const navigate = useNavigate();

  // Xử lý khi thay đổi dữ liệu
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Xử lý khi focus
  const handleFocus = (name) => {
    setFocusedInput(name);
  };

  // Xử lý khi blur
  const handleBlur = () => {
    setFocusedInput(null);
  };

  // Xóa dữ liệu và chuyển hướng đến trang đăng nhập
  const clearDataAndNavigate = async () => {
    const keysToRemove = ["token", "userData", "userId", "permissions"];
    for (const key of keysToRemove) {
      await removeFromIndexedDB(key);
    }
    navigate("/login");
  };

  // Xử lý nút submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");
    setError("");

    try {
      if (isInitialState) {
        // Gửi yêu cầu khôi phục mật khẩu
        const response = await forgetPassword(formData.email);
        if (response.message === "valid email") {
          await sendResetPasswordEmail(formData.email);
        }
        setMessage(
          "Vui lòng kiểm tra hộp mail (Hoặc trong hộp thư spam) và làm theo hướng dẫn để đặt lại mật khẩu"
        );
        setIsRequestSent(true);
        setTimeout(clearDataAndNavigate, 3000); // Chuyển hướng sau 3 giây
      } else {
        // Đặt lại mật khẩu
        if (formData.newPassword !== formData.confirmNewPassword) {
          setError("Mật khẩu mới và xác nhận mật khẩu không khớp");
          setIsLoading(false);
          return;
        }
        const response = await forgetPassword(
          formData.email,
          formData.newPassword,
          token
        );
        setMessage("Đổi mật khẩu thành công");
        setIsPasswordChanged(true);
        setTimeout(clearDataAndNavigate, 3000); // Chuyển hướng sau 3 giây
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "Đã xảy ra lỗi. Vui lòng thử lại."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen w-full bg-gradient-to-r from-gray-900 to-gray-800 font-sans">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-t-lg -mx-8 -mt-8 mb-6 p-4">
          <h2 className="text-2xl font-bold text-white text-center">
            {isInitialState ? "Khôi phục mật khẩu" : "Đặt lại mật khẩu"}
          </h2>
        </div>

        {message && (
          <div className="mb-2 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
            {message}
          </div>
        )}

        {error && (
          <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}

        {!isRequestSent && !isPasswordChanged && (
          <>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="relative">
                <CIcon
                  icon={!isInitialState ? cilWarning : cilEnvelopeClosed}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                />
                <input
                  className={`w-full py-2 pl-10 pr-3 rounded-md border ${
                    focusedInput === "email"
                      ? "border-blue-500 ring-2 ring-blue-200"
                      : "border-gray-600"
                  } bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition duration-300 ${
                    !isInitialState ? "cursor-not-allowed" : ""
                  }`}
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  onFocus={() => handleFocus("email")}
                  onBlur={handleBlur}
                  required
                  disabled={!isInitialState}
                />
                {!isInitialState && (
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    <CIcon icon={cilLockLocked} className="text-gray-400" />
                  </div>
                )}
              </div>

              {!isInitialState && (
                <>
                  <div className="relative">
                    <CIcon
                      icon={cilLockLocked}
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    />
                    <input
                      className={`w-full py-2 pl-10 pr-3 rounded-md border ${
                        focusedInput === "newPassword"
                          ? "border-blue-500 ring-2 ring-blue-200"
                          : "border-gray-600"
                      } bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition duration-300`}
                      type="password"
                      name="newPassword"
                      placeholder="Mật khẩu mới"
                      value={formData.newPassword}
                      onChange={handleChange}
                      onFocus={() => handleFocus("newPassword")}
                      onBlur={handleBlur}
                      required
                    />
                  </div>
                  <div className="relative">
                    <CIcon
                      icon={cilLockLocked}
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    />
                    <input
                      className={`w-full py-2 pl-10 pr-3 rounded-md border ${
                        focusedInput === "confirmNewPassword"
                          ? "border-blue-500 ring-2 ring-blue-200"
                          : "border-gray-600"
                      } bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition duration-300`}
                      type="password"
                      name="confirmNewPassword"
                      placeholder="Xác nhận mật khẩu mới"
                      value={formData.confirmNewPassword}
                      onChange={handleChange}
                      onFocus={() => handleFocus("confirmNewPassword")}
                      onBlur={handleBlur}
                      required
                    />
                  </div>
                </>
              )}

              <button
                className={`w-full py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-md font-semibold transition duration-300 ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:from-blue-700 hover:to-purple-700 active:from-blue-800 active:to-purple-800"
                } flex justify-center items-center`}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Đang xử lý...
                  </>
                ) : isInitialState ? (
                  "Gửi yêu cầu khôi phục mật khẩu"
                ) : (
                  "Cập nhật mật khẩu"
                )}
              </button>
            </form>

            {/* Nút Đăng nhập */}
            <div className="flex justify-center mt-6">
              <Link
                to="/login"
                className="font-semibold inline-flex items-center px-4 py-2 bg-white rounded-md text-gray-800 hover:bg-gray-100 transition duration-300 active:scale-95 no-underline hover:shadow-md"
              >
                <CIcon icon={cilUser} className="mr-2" />
                <span>Đăng nhập</span>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgetPasswordForm;
