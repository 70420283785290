// API Keys
const OPENROUTE_API_KEY =
  "5b3ce3597851110001cf6248ca6f66c823cf43d7af15c569cc421922";

// Mapbox token
const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoibWluaGhvYW5nMTEwNSIsImEiOiJjbTFxMm90OWswOWt6MmtyOGNpMHk2MWYzIn0.lpIiQCyeIyvM5Rb3tqnofg";

// Map Settings
const DEFAULT_CENTER = [10.830608, 106.690979];
const DEFAULT_ZOOM = 13;

// Server Settings
const SERVER_URL = "https://giaothong.info";

// API URL
const API_URL = "https://giaothong.info/api";

// Marker Icons
const MARKER_ICON_URLS = {
  red: `${SERVER_URL}/icons/red_location.gif`,
  blue: `${SERVER_URL}/icons/blue_location.gif`,
};

const MARKER_SHADOW_URL =
  "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png";

// Database Configuration
const DB_CONFIG = {
  host: "139.99.91.117",
  port: 61238,
  user: "tsm_user_db",
  password: "HHaC53Abz7jYT8kp",
  database: "traffic_db",
};

// Xuất các biến và hằng số
module.exports = {
  OPENROUTE_API_KEY,
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  MARKER_ICON_URLS,
  MARKER_SHADOW_URL,
  SERVER_URL,
  DB_CONFIG,
  API_URL,
  MAPBOX_ACCESS_TOKEN,
};
