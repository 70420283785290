import axios from "axios";
import { format, isValid } from "date-fns";
import { API_URL } from "../config";
import { decryptData } from "../utils/encryption";
import { getFromIndexedDB } from "../utils/indexedDB";

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  async (config) => {
    const token = await getFromIndexedDB("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Loại biển báo
export const getSignTypes = () => {
  return api.get("/sign-types");
};
export const createSignType = (data) => {
  return api.post("/sign-types", data);
};
export const updateSignType = (id, data) => {
  return api.put(`/sign-types/${id}`, data);
};
export const deleteSignType = (id) => {
  console.log("Đang xóa loại biển báo:", id);
  return api.delete(`/sign-types/${id}`);
};

// Biểu tượng icon trong biển báo
export const getIcons = async () => {
  try {
    const response = await api.get("/icons");
    return response.data; // Đảm bảo response.data là một mảng
  } catch (error) {
    console.error("Lỗi khi lấy danh sách biểu tượng:", error);
    return []; // Trả về mảng rỗng nếu có lỗi
  }
};

export const createIcon = (formData) => {
  return api
    .post("/icons", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((error) => {
      console.error("Error in createIcon API call:", error);
      throw error;
    });
};

export const updateIcon = (id, formData) => {
  return api.put(`/icons/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteIcon = (id) => {
  return api.delete(`/icons/${id}`);
};

// Biển báo
export const getSigns = () => {
  return api.get("/signs");
};
export const createSign = (data) => {
  return api.post("/signs", data);
};
export const updateSign = (id, data) => {
  return api.put(`/signs/${id}`, data);
};
export const deleteSign = (id) => {
  return api.delete(`/signs/${id}`);
};
export const getSignById = async (id) => {
  try {
    const response = await api.get(`/signs/${id}`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thông tin biển báo:", error);
    throw error;
  }
};
export const getRecentUsedSigns = async (limit, excludeIds = []) => {
  try {
    const excludeParam = excludeIds.join(",");
    const response = await api.get(
      `/signs/recent-used/${limit}?exclude=${excludeParam}`
    );
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy danh sách biển báo sử dụng gần đây:", error);
    throw error;
  }
};

export const getMostUsedSigns = async (limit, excludeIds = []) => {
  try {
    const excludeParam = excludeIds.join(",");
    const response = await api.get(
      `/signs/most-used/${limit}?exclude=${excludeParam}`
    );
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy danh sách biển báo sử dụng nhiều nhất:", error);
    throw error;
  }
};

// Biển báo phụ
export const getSubsigns = () => {
  return api.get("/subsigns");
};

export const getSubsignById = (id) => {
  return api.get(`/subsigns/${id}`);
};

export const createSubsign = (data) => {
  return api.post("/subsigns", data);
};

export const updateSubsign = (id, data) => {
  return api.put(`/subsigns/${id}`, data);
};

export const deleteSubsign = (id) => {
  return api.delete(`/subsigns/${id}`);
};

// Loại biển báo phụ
export const getSubsignTypes = () => {
  return api.get("/subsign-types");
};

export const createSubsignType = (data) => {
  return api.post("/subsign-types", data);
};

export const updateSubsignType = (id, data) => {
  return api.put(`/subsign-types/${id}`, data);
};

export const deleteSubsignType = (id) => {
  return api.delete(`/subsign-types/${id}`);
};

// Đăng nhập
export const getUser = () => api.get("/user").then((res) => res.data);
export const login = (credentials) =>
  api.post("/login", credentials).then((res) => res.data);
export const logout = () => api.post("/logout").then((res) => res.data);
export const register = (userData) =>
  api.post("/register", userData).then((res) => res.data);

// Người dùng
// Hàm mới để lấy thông tin người dùng theo ID
export const getUserById = async (id) => {
  try {
    const response = await api.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thông tin người dùng:", error);
    throw error;
  }
};

// Lấy danh sách người dùng
export const getUsers = async (page = 1, limit = 10) => {
  try {
    const response = await api.get(`/users?page=${page}&limit=${limit}`);
    return {
      data: response.data.map((user) => {
        let formattedDate = null;
        if (user.date_of_birth) {
          const date = new Date(user.date_of_birth);
          if (isValid(date)) {
            formattedDate = format(date, "dd/MM/yyyy");
          }
        }
        return {
          ...user,
          date_of_birth: formattedDate,
        };
      }),
    };
  } catch (error) {
    console.error("Error in getUsers:", error);
    throw error;
  }
};

// Lấy thông tin icon và avatar của nhiều người dùng
export const getUserIcons = async (userIds) => {
  try {
    const response = await api.get("/users/icons", {
      params: { userIds: userIds.join(",") },
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thông tin icon của người dùng:", error);
    throw error;
  }
};

// Tạo người dùng mới
export const createUser = async (userData) => {
  try {
    const response = await api.post("/users", userData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error("Không thể tạo người dùng mới");
    }
  } catch (error) {
    console.error("Lỗi khi tạo người dùng mới:", error);
    throw error;
  }
};

// Cập nhật thông tin người dùng
export const updateUser = async (id, userData) => {
  try {
    const response = await api.put(`/users/${id}`, userData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // Chuyển đổi ngày tháng về định dạng dd/MM/yyyy trước khi trả về
    if (response.data.date_of_birth) {
      response.data.date_of_birth = format(
        new Date(response.data.date_of_birth),
        "dd/MM/yyyy"
      );
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Cập nhật thông tin người dùng
export const updateUserProfile = async (id, userData) => {
  try {
    const response = await api.put(`/users/profile/${id}`, userData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // Chuyển đổi ngày tháng về định dạng dd/MM/yyyy trước khi trả về
    if (response.data.date_of_birth) {
      response.data.date_of_birth = format(
        new Date(response.data.date_of_birth),
        "dd/MM/yyyy"
      );
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Xóa người dùng
export const deleteUser = (id) => {
  return api.delete(`/users/${id}`);
};

// Thêm hàm mới để cập nhật mật khẩu người dùng
export const updateUserPassword = async (userId, { newPassword }) => {
  try {
    const response = await api.put(`/users/${userId}/password`, {
      newPassword,
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi cập nhật mật khẩu:", error);
    throw error;
  }
};

// Quên mật khẩu
export const forgetPassword = async (
  email,
  newPassword = null,
  verificationToken = null
) => {
  try {
    const data = { email };
    if (newPassword) {
      data.newPassword = newPassword;
    }
    if (verificationToken) {
      data.verificationToken = verificationToken;
    }
    const response = await api.post("/users/forget-password", data);
    return response.data;
  } catch (error) {
    console.error("Lỗi trong quá trình xử lý quên mật khẩu:", error);
    throw error;
  }
};

// Cấp độ ( LEVEL )
// Cấp độ người dùng
export const getLevels = () => {
  return api.get("/levels");
};

export const createLevel = (formData) => {
  return api.post("/levels", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateLevel = (id, formData) => {
  return api.put(`/levels/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteLevel = (id) => {
  return api.delete(`/levels/${id}`);
};

export const getLevelByUserId = (userId) => {
  return api.get(`/levels/by-user/${userId}`);
};

// Vai trò ( ROLE )
// Vai trò người dùng
export const getRoles = () => {
  return api.get("/roles");
};

export const createRole = (formData) => {
  return api.post("/roles", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateRole = (id, formData) => {
  return api.put(`/roles/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteRole = (id) => {
  return api.delete(`/roles/${id}`);
};

export const getRoleByUserId = (userId) => {
  return api.get(`/roles/by-user/${userId}`);
};

// Biến báo
// Lấy danh sách biển báo trên bản đồ
export const getMapSigns = async () => {
  try {
    const response = await api.get("/map-signs");
    if (response.data.encryptedData) {
      const decryptedData = decryptData(response.data.encryptedData);
      return decryptedData;
    } else {
      console.warn("Data is not encrypted");
      return response.data;
    }
  } catch (error) {
    console.error("Lỗi khi lấy danh sách biển báo:", error);
    throw error;
  }
};

// Lấy tất cả biển báo của người dùng đang đăng nhập
export const getMyMapSigns = async () => {
  try {
    const response = await api.get("/map-signs/me");
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy danh sách biển báo của người dùng:", error);
    throw error;
  }
};

// Lấy danh sách biển báo trong một khu vực nhất định
export const getMapSignsByArea = async (minLat, maxLat, minLng, maxLng) => {
  try {
    const response = await api.get("/map-signs/area", {
      params: { minLat, maxLat, minLng, maxLng },
    });
    if (response.data.encryptedData) {
      const decryptedData = decryptData(response.data.encryptedData);
      return decryptedData;
    } else {
      console.warn("Dữ liệu không được mã hóa");
      console.log("Dữ liệu biển báo gốc:", response.data); // Thêm log này
      return response.data;
    }
  } catch (error) {
    console.error("Lỗi khi lấy danh sách biển báo theo khu vực:", error);
    throw error;
  }
};

// Tạo biển báo mới trên bản đồ
export const createMapSign = async (data) => {
  try {
    const response = await api.post("/map-signs", data);
    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || "Không thể thêm biển báo");
    }
  } catch (error) {
    throw error;
  }
};

// Cập nhật thông tin biển báo trên bản đồ
export const updateMapSign = (id, data) => {
  const { status, ...updateData } = data;
  return api.put(`/map-signs/${id}`, updateData);
};

// Cập nhật trạng thái biển báo
export const updateStatusMapSign = (id, status) => {
  return api.patch(`/map-signs/${id}/status`, { status });
};

// Cập nhật trạng thái biển báo hàng loạt
export const bulkUpdateStatusMapSigns = async (signIds, status) => {
  try {
    const response = await api.post("/map-signs/bulk-update-status", {
      signIds,
      status,
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi cập nhật hàng loạt trạng thái biển báo:", error);
    throw error;
  }
};

// Cập nhật tên đường cho biển báo
export const updateRoadNames = async (idString) => {
  try {
    const response = await api.post("/map-signs/update-road-names", {
      ids: idString,
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi cập nhật road_name:", error);
    throw error;
  }
};

// Xóa biển báo khỏi bản đồ
export const deleteMapSign = (id) => {
  return api.delete(`/map-signs/${id}`);
};

// Xóa biển báo khỏi bản đồ dành cho users
export const deleteMapSignForUser = (id) => {
  return api.delete(`/map-signs/users/${id}`);
};

// Lấy biển báo bằng Id biển báo
export const getMapSignById = async (id) => {
  try {
    const response = await api.get(`/map-signs/${id}`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thông tin biển báo:", error);
    throw error;
  }
};

// Tạo biển báo mới trên bản đồ với trạng thái đã được phê duyệt
export const createApprovedMapSign = async (data) => {
  try {
    const response = await api.post("/map-signs/approve", data);
    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(
        response.data.error || "Không thể thêm biển báo đã được phê duyệt"
      );
    }
  } catch (error) {
    throw error;
  }
};

// Góp ý biển báo
// Tạo góp ý mới biển báo
export const createMapSignSuggestion = async (signId, data) => {
  try {
    const response = await api.post(`/map-signs/suggestions/${signId}`, data);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi tạo góp ý:", error);
    throw error;
  }
};

// Lấy tất cả góp ý của biển báo đó
export const getMapSignSuggestions = async (signId) => {
  try {
    const response = await api.get(`/map-signs/suggestions/${signId}`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy danh sách góp ý:", error);
    throw error;
  }
};

// Chấp nhận hoặc từ chối góp ý biển báo
export const approveRejectMapSignSuggestion = async (suggestionId, action) => {
  try {
    const response = await api.patch(`/map-signs/suggestions/${suggestionId}`, {
      action,
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi xử lý góp ý:", error);
    throw error;
  }
};

// Phân quyền
// Phân quyền người dùng
export const getPermissionsByRoleId = async (roleId) => {
  try {
    const response = await api.get(`/permissions/${roleId}`);
    return response.data;
  } catch (error) {
    console.error(`Lỗi khi lấy quyền cho vai trò ${roleId}:`, error);
    throw error;
  }
};

// Cập nhật quyền cho vai trò
export const updatePermissions = async (roleId, permissions) => {
  try {
    const response = await api.put(`/permissions/${roleId}`, permissions);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi cập nhật quyền:", error);
    throw error;
  }
};

// Lấy danh sách tất cả quyền
export const getAllPermissions = async () => {
  try {
    // Sửa đổi đường dẫn API để lấy tất cả quyền
    const response = await api.get("/permissions");
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy danh sách quyền:", error);
    throw error;
  }
};

// Thông báo notification
export const getNotifications = async () => {
  try {
    const response = await api.get("/notifications");
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thông báo:", error);
    throw error;
  }
};

export const markNotificationAsRead = async (notificationId) => {
  try {
    const response = await api.patch(`/notifications/${notificationId}/read`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi đánh dấu thông báo đã đọc:", error);
    throw error;
  }
};

export const deleteNotification = async (notificationId) => {
  try {
    const response = await api.delete(`/notifications/${notificationId}`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi xóa thông báo:", error);
    throw error;
  }
};

export const deleteAllNotifications = async () => {
  try {
    const response = await api.delete("/notifications");
    return response.data;
  } catch (error) {
    console.error("Lỗi khi xóa tất cả thông báo:", error);
    throw error;
  }
};

// Góp ý người dùng
export const createUserFeedback = async (data) => {
  try {
    const response = await api.post("/user-feedback", data);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi tạo góp ý:", error);
    if (error.response && error.response.data) {
      console.error("Chi tiết lỗi từ server:", error.response.data);
    }
    throw error;
  }
};

export const getUserFeedbacks = async () => {
  try {
    const response = await api.get("/user-feedback");
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy danh sách góp ý:", error);
    throw error;
  }
};

export const getUserFeedbackById = async (id) => {
  try {
    const response = await api.get(`/user-feedback/${id}`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thông tin góp ý:", error);
    throw error;
  }
};

export const replyUserFeedback = async (id, content) => {
  try {
    const response = await api.post(`/user-feedback/${id}/reply`, { content });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi trả lời góp ý:", error);
    throw error;
  }
};

export const closeUserFeedback = async (id) => {
  try {
    const response = await api.patch(`/user-feedback/${id}/close`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi đóng góp ý:", error);
    throw error;
  }
};

export const deleteUserFeedback = async (id) => {
  try {
    const response = await api.delete(`/user-feedback/${id}`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi xóa góp ý:", error);
    throw error;
  }
};

// Thống kê
// Lấy thống kê dashboard
export const getDashboardStats = async () => {
  try {
    const response = await api.get("/stats/dashboard");
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thống kê dashboard:", error);
    throw error;
  }
};

// Thống kê biển báo theo ngày
export const getSignsByDate = async (startDate, endDate, signTypeId) => {
  try {
    const response = await api.get("/stats/signs-by-date", {
      params: { startDate, endDate, signTypeId },
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thống kê biển báo theo ngày:", error);
    throw error;
  }
};

// Thống kê tài khoản được tạo hằng tháng
export const getUsersByMonth = async (year) => {
  try {
    const response = await api.get(`/stats/users-by-month`, {
      params: { year },
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thống kê người dùng theo tháng:", error);
    throw error;
  }
};

// Thống kế số lượng biển báo được tạo bởi người dùng
export const getTopUsersBySignCount = async (limit = 10) => {
  try {
    const response = await api.get("/stats/top-users-by-signs", {
      params: { limit },
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy top người dùng tạo biển báo:", error);
    throw error;
  }
};
// Thống kê biển báo theo địa điểm
export const getSignsStats = async () => {
  try {
    const response = await api.get("/stats/signs-stats");
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thống kê biển báo theo địa điểm:", error);
    throw error;
  }
};

// Thống kế số đường đã được cập nhật hoàn tất theo ngày
export const getUpdatedRoadsByDate = async (startDate, endDate) => {
  try {
    const response = await api.get("/stats/updated-roads-by-date", {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy thống kê đường đã cập nhật theo ngày:", error);
    throw error;
  }
};

// Trạng thái cập nhật của đường
// Lấy trạng thái cập nhật của đường
export const getSignsUpdateStatus = async () => {
  try {
    const response = await api.get("/stats/road-update-status");
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy trạng thái cập nhật đường:", error);
    throw error;
  }
};

// Cập nhật trạng thái của đường
export const updateRoadStatus = async (city, district, street, status) => {
  try {
    const response = await api.post("/stats/update-road-status", {
      city,
      district,
      street,
      status,
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi cập nhật trạng thái đường:", error);
    throw error;
  }
};

// Theo dõi dữ liệu người dùng
// Lấy danh sách phiên người dùng
export const getUserSessions = async () => {
  try {
    const response = await api.get(`/analytics/user-sessions`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy danh sách phiên người dùng:", error);
    throw error;
  }
};

// Theo dõi người dùng
export const trackUser = async (userData) => {
  try {
    const response = await api.post("/analytics/track-user", userData);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi gửi dữ liệu theo dõi người dùng:", error);
    throw error;
  }
};

// Cập nhật phiên
export const updateSession = async (sessionData) => {
  try {
    const response = await api.put("/analytics/update-session", sessionData);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi cập nhật phiên:", error);
    throw error;
  }
};

// Xóa phiên người dùng
export const deleteUserSession = async (sessionId) => {
  try {
    const response = await api.delete(`/analytics/user-sessions/${sessionId}`);
    return response.data;
  } catch (error) {
    console.error("Lỗi khi xóa phiên người dùng:", error);
    throw error;
  }
};

// Gửi email
// Gửi email xác minh
export const sendVerificationEmail = async (email) => {
  try {
    const response = await api.post("/users/send-verification-email", {
      email,
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi gửi email xác minh:", error);
    throw error;
  }
};

// Gửi email đặt lại mật khẩu
export const sendResetPasswordEmail = async (email) => {
  try {
    const response = await api.post("/users/send-reset-password-email", {
      email,
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi gửi email đặt lại mật khẩu:", error);
    throw error;
  }
};

// Xác minh email
export const verifyEmail = async (token, userId, isResetPassword = false) => {
  try {
    const response = await api.get(
      `/users/verify-email?token=${token}&userId=${userId}&isResetPassword=${isResetPassword}`
    );
    return response.data;
  } catch (error) {
    console.error("Lỗi khi xác minh email:", error);
    throw error;
  }
};

// Gửi email liên hệ
export const sendContactEmail = async (name, email, message) => {
  try {
    const response = await api.post("/email/contact", {
      name,
      email,
      message,
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi gửi email liên hệ:", error);
    throw error;
  }
};

// Cài đặt
// Lấy cài đặt
export const getSettings = async () => {
  try {
    const response = await api.get("/settings");
    return response.data;
  } catch (error) {
    console.error("Lỗi khi lấy cài đặt:", error);
    throw error;
  }
};

// Cập nhật cài đặt
export const updateSettings = async (formData) => {
  try {
    const response = await api.put("/settings", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Lỗi khi cập nhật cài đặt:", error);
    throw error;
  }
};

// Xử lý lỗi
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(
      "Lỗi API:",
      error.config.method,
      error.config.url,
      error.response?.status,
      error.response?.data
    );
    return Promise.reject(error);
  }
);

export default api;
