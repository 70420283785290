import React, { useMemo } from "react";

const HeightLimitRoundSignComponent = ({
  size = 100,
  backgroundColor,
  content,
  borderColor,
  contentColor,
  contentStrokeColor,
  contentStrokeWidth,
  isIcon = false,
}) => {
  const buttonSize = useMemo(
    () => (typeof size === "number" && !isNaN(size) ? size : 100),
    [size]
  );
  const fontSize = useMemo(() => Math.floor(buttonSize * 0.3), [buttonSize]);
  const borderWidth = useMemo(
    () => Math.max(3, Math.floor(buttonSize * 0.08)),
    [buttonSize]
  );

  // Logic cho tam giác nhỏ
  const smallTriangleSize = useMemo(() => buttonSize * 0.2, [buttonSize]);
  const smallTriangleHeight = useMemo(
    () => (Math.sqrt(3) / 2) * smallTriangleSize,
    [smallTriangleSize]
  );

  const renderSmallTriangle = useMemo(
    () => (isTop) => {
      const halfSize = buttonSize / 2;
      const yOffset = isTop
        ? borderWidth / 2
        : buttonSize - borderWidth / 2 - smallTriangleHeight;

      const points = isTop
        ? `${halfSize},${yOffset + smallTriangleHeight} ${
            halfSize - smallTriangleSize / 2
          },${yOffset} ${halfSize + smallTriangleSize / 2},${yOffset}`
        : `${halfSize},${yOffset} ${halfSize - smallTriangleSize / 2},${
            yOffset + smallTriangleHeight
          } ${halfSize + smallTriangleSize / 2},${
            yOffset + smallTriangleHeight
          }`;

      return (
        <polygon
          points={points}
          fill={contentColor}
          stroke={contentColor}
          strokeWidth={borderWidth / 2}
        />
      );
    },
    [
      buttonSize,
      smallTriangleSize,
      smallTriangleHeight,
      contentColor,
      borderWidth,
    ]
  );

  return (
    <div
      style={{
        width: `${buttonSize}px`,
        height: `${buttonSize}px`,
        position: "relative",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${buttonSize} ${buttonSize}`}
        width={buttonSize}
        height={buttonSize}
      >
        <circle
          cx={buttonSize / 2}
          cy={buttonSize / 2}
          r={(buttonSize - borderWidth) / 2}
          fill={backgroundColor}
        />
        {renderSmallTriangle(true)}
        {renderSmallTriangle(false)}
        {isIcon ? (
          <foreignObject x="10%" y="10%" width="80%" height="80%">
            <div
              xmlns="http://www.w3.org/1999/xhtml"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: contentColor,
                fontSize: `${fontSize}px`,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {content}
            </div>
          </foreignObject>
        ) : (
          <text
            x={buttonSize / 2}
            y={buttonSize / 2}
            fontSize={fontSize}
            textAnchor="middle"
            dominantBaseline="central"
            fill={contentColor}
            stroke={contentStrokeColor}
            strokeWidth={contentStrokeWidth}
            fontFamily="Arial"
            fontWeight="bold"
          >
            {content}
          </text>
        )}
        <circle
          cx={buttonSize / 2}
          cy={buttonSize / 2}
          r={(buttonSize - borderWidth) / 2}
          fill="none"
          stroke={borderColor}
          strokeWidth={borderWidth}
        />
      </svg>
    </div>
  );
};

export default React.memo(HeightLimitRoundSignComponent);
