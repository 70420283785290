import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Liên kết nhanh</h3>
            <ul className="space-y-2 ">
              <li>
                <a href="/" className="no-underline">
                  Bản đồ số biển báo giao thông
                </a>
              </li>
              <li>
                <a href="/home/data" className="no-underline">
                  Trang dữ liệu thống kê
                </a>
              </li>
              <li>
                <a href="/home/contact" className="no-underline">
                  Trang liên hệ
                </a>
              </li>
              <li>
                <a href="/home/copyrights" className="no-underline">
                  Trang bản quyền
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Thông tin liên hệ</h3>
            <p>Email: info@giaothong.info</p>
            <div className="flex space-x-4 mt-2">
              <a
                href="https://zalo.me/0938871357"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/media/icons/zalo.png"
                  alt="Zalo"
                  className="w-6 h-6"
                />
              </a>
              <a
                href="https://t.me/minhhoang_1105"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/media/icons/telegram.png"
                  alt="Telegram"
                  className="w-6 h-6"
                />
              </a>
              <a
                href="https://m.me/100001463683827"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/media/icons/messenger.webp"
                  alt="Messenger"
                  className="w-6 h-6"
                />
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Theo dõi chúng tôi</h3>
            {/* Thêm các biểu tượng mạng xã hội ở đây */}
          </div>
        </div>
        <div className="mt-8 text-center">
          <p>&copy; 2024 Giaothong.info. Tất cả quyền được bảo lưu.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
