import React, { useState, useRef, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaPlus,
  FaEdit,
  FaTrash,
  FaUser,
  FaCheck,
  FaTimes,
  FaBell,
  FaFilter,
  FaCheckDouble,
  FaTrashAlt,
  FaComment,
  FaReply,
  FaLock,
  FaCheckCircle,
  FaKey,
} from "react-icons/fa";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  deleteNotification,
  deleteAllNotifications,
  getNotifications,
} from "../../services/api";
import toast from "react-hot-toast";

const NotificationList = ({
  notifications,
  markNotificationAsRead,
  isVisible,
  onMouseEnter,
  onMouseLeave,
  position = { top: "100%", right: "0" },
  customItemRenderer,
  customEmptyRenderer,
  onNotificationsUpdated,
}) => {
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const scrollableNodeRef = useRef(null);

  const getIcon = (type) => {
    switch (type) {
      case "add_sign":
        return <FaPlus className="text-green-500" />;
      case "edit_sign":
        return <FaEdit className="text-blue-500" />;
      case "delete_sign":
        return <FaTrash className="text-red-500" />;
      case "new_user":
      case "welcome_user":
        return <FaUser className="text-purple-500" />;
      case "approve_sign":
        return <FaCheck className="text-green-500" />;
      case "reject_sign":
        return <FaTimes className="text-red-500" />;
      case "new_feedback":
        return <FaComment className="text-yellow-500" />;
      case "feedback_reply":
        return <FaReply className="text-blue-500" />;
      case "feedback_close":
        return <FaLock className="text-gray-500" />;
      case "verified_email":
        return <FaCheckCircle className="text-green-500" />;
      case "change_password":
        return <FaKey className="text-gray-500" />;
      default:
        return null;
    }
  };

  const getContent = (notification) => {
    switch (notification.type) {
      case "welcome_user":
        return (
          <>
            <span className="font-normal">Chào mừng</span>{" "}
            <span className="font-bold">
              {notification.user_request_fullname}
            </span>{" "}
            <span className="font-normal">đến với cộng đồng TSM</span>
          </>
        );
      case "new_user":
        return (
          <>
            <span className="font-normal">Người dùng</span>{" "}
            <span className="font-bold">
              {notification.user_request_fullname}
            </span>{" "}
            <span className="font-normal">
              <span className="italic">(#{notification.user_request_id})</span>{" "}
              đã được tạo
            </span>
          </>
        );
      case "add_sign":
        return (
          <>
            <span className="font-bold">
              {notification.user_request_fullname}
            </span>{" "}
            <span className="font-normal">
              đã thêm biển báo mới{" "}
              <span className="italic">(#{notification.item_id})</span>
            </span>
          </>
        );
      case "edit_sign":
        return (
          <>
            <span className="font-normal">Biển báo</span>{" "}
            <span className="font-bold">#{notification.item_id}</span>{" "}
            <span className="font-normal">của bạn vừa được cập nhật</span>
          </>
        );
      case "approve_sign":
        return (
          <>
            <span className="font-normal">Biển báo</span>{" "}
            <span className="font-bold">#{notification.item_id}</span>{" "}
            <span className="font-normal">của bạn đã được</span>{" "}
            <span className="font-bold">chấp nhận</span>
          </>
        );
      case "reject_sign":
        return (
          <>
            <span className="font-normal">Biển báo</span>{" "}
            <span className="font-bold">#{notification.item_id}</span>{" "}
            <span className="font-normal">của bạn đã bị</span>{" "}
            <span className="font-bold">từ chối</span>
          </>
        );
      case "delete_sign":
        return (
          <>
            <span className="font-normal">Người dùng</span>{" "}
            <span className="font-bold">
              {notification.user_request_fullname}
            </span>{" "}
            <span className="font-normal">
              <span className="italic">(#{notification.user_request_id})</span>{" "}
              đã xóa biển báo
            </span>{" "}
            <span className="font-bold">#{notification.item_id}</span>
          </>
        );
      case "new_feedback":
        return (
          <>
            <span className="font-normal">{notification.content}</span>
          </>
        );
      case "feedback_reply":
        return (
          <>
            <span className="font-normal">Người dùng</span>{" "}
            <span className="font-bold">#{notification.user_request_id}</span>{" "}
            <span className="font-normal">đã trả lời góp ý của bạn</span>
          </>
        );
      case "feedback_close":
        return (
          <>
            <span className="font-normal">Góp ý</span>{" "}
            <span className="font-bold">#{notification.item_id}</span>{" "}
            <span className="font-normal">đã được đóng</span>
          </>
        );
      case "verified_email":
        return (
          <>
            <span className="font-normal">Email của bạn đã được</span>{" "}
            <span className="font-bold">xác minh thành công</span>
          </>
        );
      case "change_password":
        return (
          <>
            <span className="font-normal">Mật khẩu của bạn đã được</span>{" "}
            <span className="font-bold">thay đổi thành công</span>
          </>
        );
      default:
        return (
          <>
            <span className="font-bold">
              {notification.user_request_fullname}
            </span>{" "}
            <span className="font-normal">
              {notification.content.toLowerCase()}{" "}
              <span className="italic">(#{notification.item_id})</span>
            </span>
          </>
        );
    }
  };

  const filteredNotifications = showUnreadOnly
    ? notifications.filter((notification) => !notification.is_read)
    : notifications;

  const markAllAsRead = useCallback(() => {
    filteredNotifications.forEach((notification) => {
      if (!notification.is_read) {
        markNotificationAsRead(notification.id);
      }
    });
  }, [filteredNotifications, markNotificationAsRead]);

  const handleDeleteNotification = async (notificationId) => {
    try {
      await deleteNotification(notificationId);
      toast.success("Đã xóa thông báo thành công");
      const updatedNotifications = await getNotifications();
      onNotificationsUpdated(updatedNotifications);
    } catch (error) {
      console.error("Lỗi khi xóa thông báo:", error);
      toast.error("Có lỗi xảy ra khi xóa thông báo");
    }
  };

  const handleDeleteAllNotifications = async () => {
    try {
      await deleteAllNotifications();
      toast.success("Đã xóa tất cả thông báo thành công");
      const updatedNotifications = await getNotifications();
      onNotificationsUpdated(updatedNotifications);
    } catch (error) {
      console.error("Lỗi khi xóa tất cả thông báo:", error);
      toast.error("Có lỗi xảy ra khi xóa tất cả thông báo");
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="notification-list absolute bg-white border border-gray-200 rounded-md shadow-lg z-50 w-full sm:w-96 max-h-[90vh] sm:max-h-96 mx-auto sm:mr-2"
          style={{
            ...position,
            left: window.innerWidth <= 640 ? "0" : position.left,
            right: window.innerWidth <= 640 ? "0" : position.right,
          }}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {/* Header của danh sách thông báo */}
          <div className="flex justify-between items-center p-2 border-b border-gray-200">
            <div>
              <button
                onClick={() => setShowUnreadOnly(false)}
                className={`mr-2 ${
                  !showUnreadOnly ? "text-blue-500" : "text-gray-500"
                } hover:text-blue-700`}
                title="Hiển thị tất cả thông báo"
              >
                <FaBell />
              </button>
              <button
                onClick={() => setShowUnreadOnly(true)}
                className={`${
                  showUnreadOnly ? "text-blue-500" : "text-gray-500"
                } hover:text-blue-700`}
                title="Chỉ hiển thị thông báo chưa đọc"
              >
                <FaFilter />
              </button>
            </div>
            <div>
              <button
                onClick={markAllAsRead}
                className="text-gray-500 hover:text-blue-500 mr-2"
                title="Đánh dấu tất cả là đã đọc"
              >
                <FaCheckDouble />
              </button>
              <button
                onClick={handleDeleteAllNotifications}
                className="text-gray-500 hover:text-red-500"
                title="Xóa tất cả thông báo"
              >
                <FaTrashAlt />
              </button>
            </div>
          </div>

          {/* Danh sách thông báo với cuộn */}
          <PerfectScrollbar
            containerRef={(ref) => (scrollableNodeRef.current = ref)}
            options={{ suppressScrollX: true, minScrollbarLength: 50 }}
            className="max-h-[calc(90vh-56px)] sm:max-h-80"
          >
            {filteredNotifications.length > 0 ? (
              filteredNotifications.map((notification) =>
                customItemRenderer ? (
                  customItemRenderer(notification, () =>
                    markNotificationAsRead(notification.id)
                  )
                ) : (
                  <div
                    key={notification.id}
                    className={`flex items-center p-2 border-b border-gray-200 cursor-pointer text-sm ${
                      notification.is_read ? "bg-white" : "bg-blue-50"
                    }`}
                  >
                    <div
                      className="flex-grow p-1"
                      onClick={() => markNotificationAsRead(notification.id)}
                    >
                      <div className="flex items-center">
                        <div className="flex-shrink-0 mr-3">
                          {getIcon(notification.type)}
                        </div>
                        <div className="break-words">
                          {getContent(notification)}
                        </div>
                      </div>
                      <div className="text-xs text-gray-500 mt-1">
                        {new Date(notification.created_at).toLocaleString(
                          "en-GB"
                        )}
                      </div>
                    </div>
                    {notification.is_read ? (
                      <button
                        onClick={() =>
                          handleDeleteNotification(notification.id)
                        }
                        className="text-gray-400 hover:text-red-500 ml-2"
                        title="Xóa thông báo"
                      >
                        <FaTimes />
                      </button>
                    ) : (
                      <span className="w-2 h-2 bg-blue-500 rounded-full ml-2"></span>
                    )}
                  </div>
                )
              )
            ) : customEmptyRenderer ? (
              customEmptyRenderer()
            ) : (
              <div className="p-4 text-center text-gray-500">
                Không có thông báo mới
              </div>
            )}
          </PerfectScrollbar>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NotificationList;
